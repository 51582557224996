import React from "react";
import { Skeleton, useMediaQuery } from "@mui/material";
import "./SearchView.css";

function SearchViewSkeleton() {
  const isMobile = useMediaQuery("(max-width:1024px)");
  return (
    <div className="searchViewSkeleton">
      <div className="leftSide">
        <Skeleton variant="rectangular" width="100%" height={200} sx={{ bgcolor: '#e6e6e6' }} />
      </div>
      <div className="middleContent">
        <div className="middleFilters">
          <div className="leftFilters">
            <Skeleton variant="rectangular" width="100%" height={50} sx={{ bgcolor: '#e6e6e6' }} />
          </div>
          <div className="rightFilters">
            <Skeleton variant="text" width={100} sx={{ bgcolor: '#e6e6e6' }} />
            <Skeleton variant="text" width={100} sx={{ bgcolor: '#e6e6e6' }} />
          </div>
        </div>
        <div className="middleContentBox">
          <Skeleton variant="rectangular" width="100%" height={150} sx={{ bgcolor: '#e6e6e6' }} />
        </div>
        <div className="middleContentBox">
          <Skeleton variant="rectangular" width="100%" height={150} sx={{ bgcolor: '#e6e6e6' }} />
        </div>
        <div className="middleContentBox">
          <Skeleton variant="rectangular" width="100%" height={150} sx={{ bgcolor: '#e6e6e6' }} />
        </div>
        <div className="middleContentBox">
          <Skeleton variant="rectangular" width="100%" height={150} sx={{ bgcolor: '#e6e6e6' }} />
        </div>
        <div className="middleContentBox">
          <Skeleton variant="rectangular" width="100%" height={150} sx={{ bgcolor: '#e6e6e6' }} />
        </div>
        <div className="middleContentBox">
          <Skeleton variant="rectangular" width="100%" height={150} sx={{ bgcolor: '#e6e6e6' }} />
        </div>
      </div>
      {!isMobile && <div className="rightSide">
        <Skeleton variant="rectangular" width="100%" height={200} sx={{ bgcolor: '#e6e6e6' }} />
      </div>}
    </div>
  );
}

export default SearchViewSkeleton;
