import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material/";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";


import "./SearchBaseView.css";
import SuggestedAndPopularSearches from "./SuggestedAndPopularSearches";

const SearchBaseView: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility
  const [selectedFilters, setSelectedFilters] = useState<{
    commodity: string[];
    miningMethod: string[];
  }>({ commodity: [], miningMethod: [] }); // State to track selected filters grouped by category
  const navigate = useNavigate(); // Use the navigate function for routing

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Handle filter checkbox toggle for each category
  const handleFilterChange = (
    filter: string,
    category: "commodity" | "miningMethod"
  ) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [category]: prevFilters[category].includes(filter)
        ? prevFilters[category].filter((f) => f !== filter) // Remove filter if already selected
        : [...prevFilters[category], filter], // Add filter if not already selected
    }));
  };

  // Remove filter when the pill's cancel button is clicked
  const removeFilter = (
    filter: string,
    category: "commodity" | "miningMethod"
  ) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [category]: prevFilters[category].filter((f) => f !== filter),
    }));
  };

  // Handle search submission (on Enter key press)
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      const queryParams = [];

      // Build query for commodity filters
      if (selectedFilters.commodity.length > 0) {
        queryParams.push(`commodity=${selectedFilters.commodity.join(",")}`);
      }

      // Build query for mining method filters
      if (selectedFilters.miningMethod.length > 0) {
        queryParams.push(
          `miningMethod=${selectedFilters.miningMethod.join(",")}`
        );
      }

      if (searchTerm) {
        queryParams.push(
          `keyword=${searchTerm}`
        );
      }

      // Navigate to the test-page with query params
      const queryString = queryParams.join("&");
      navigate(`/search/regulations?${queryString}`);
    }
  };

  return (
    <div className="searchFilterWrapper">
      <div className="contentWidth">
        <div className="searchHeadContent">
          <h1>Get compliance insights</h1>
          <p>
            Enter your search criteria and access thousands of laws,
            regulations, and guidance documents.
          </p>
        </div>

        <div className="searchWrapper">
          <div className="search">
            <div className="searchLeft">
              <div className="searchIcon">
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: "inputRoot",
                  input: "inputInput",
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyPress} // Handle Enter key press
              />
            </div>
            <div className="searchRight" onClick={toggleDropdown}>
              <TuneRoundedIcon />
            </div>
          </div>

          {/* Filter Dropdown with two categories */}
          {showDropdown && (
            <div className="searchDropdown">
              <FormGroup>
                <div>
                  <h3>Commodity</h3>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.commodity.includes("coal")}
                        onChange={() => handleFilterChange("coal", "commodity")}
                      />
                    }
                    label="Coal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.commodity.includes("metal")}
                        onChange={() =>
                          handleFilterChange("metal", "commodity")
                        }
                      />
                    }
                    label="Metal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.commodity.includes("nonmetal")}
                        onChange={() =>
                          handleFilterChange("nonmetal", "commodity")
                        }
                      />
                    }
                    label="Nonmetal"
                  />
                </div>

                <div>
                  <h3>Mining Method</h3>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.miningMethod.includes(
                          "Surface"
                        )}
                        onChange={() =>
                          handleFilterChange("surface", "miningMethod")
                        }
                      />
                    }
                    label="Surface"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFilters.miningMethod.includes(
                          "Underground"
                        )}
                        onChange={() =>
                          handleFilterChange("underground", "miningMethod")
                        }
                      />
                    }
                    label="Underground"
                  />
                </div>
              </FormGroup>
            </div>
          )}
        </div>

        {/* Display selected filters as pills */}
        <div className="filterSec">
          {selectedFilters.commodity.map((filter) => (
            <span key={filter} className="appliedFilter">
              {filter}{" "}
              <span
                className="deleteFilter"
                onClick={() => removeFilter(filter, "commodity")}
              >
                <CancelIcon />
              </span>
            </span>
          ))}

          {selectedFilters.miningMethod.map((filter) => (
            <span key={filter} className="appliedFilter">
              {filter}{" "}
              <span
                className="deleteFilter"
                onClick={() => removeFilter(filter, "miningMethod")}
              >
                <CancelIcon />
              </span>
            </span>
          ))}
        </div>
       <SuggestedAndPopularSearches/>
      </div>
    </div>
  );
};

export default SearchBaseView;
