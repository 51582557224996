import React, { useState } from "react";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Skeleton from "@mui/material/Skeleton";
import { REACT_APP_SERVER_DOMAIN } from "../../constants";
import { isArrayLength } from "../../constants/genericHelpers";
import Modal from "@mui/material/Modal";
import { Worker, Viewer, PdfJs } from "@react-pdf-viewer/core";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

interface IAIResponseBlockProps {
  downloadPath: string;
  aiResult: any;
  aiFetching: boolean;
  aiResultId: string;
  aiText: string;
  updateAIFeedback: any;
  feedback: string;
  feedbackAttributeName: string;
  renderSkeleton: any;
  preSearchPrompt: string;
  aiQuotedNameString: string;
}

const renderSourceNodes = (
  source_nodes: any,
  downloadPath: any,
  setModalOpen: any,
  setNodePath: any
) => {
  const uniqueNodes = [] as any[];
  source_nodes.forEach((node: any) => {
    const existing = uniqueNodes.find(
      (x) => x?.source_url === node?.source_url
    );
    if (!existing) {
      uniqueNodes.push(node);
    }
  });
  return uniqueNodes.map((node: any, index: number) => {
    return (
      <span
        key={index}
        onClick={() => {
          setModalOpen(true);
          setNodePath(node?.signed_download_url);
        }}
      >
        {node?.friendly_title} <ArrowForwardOutlinedIcon />
      </span>
    );
  });
};

const SearchViewCoPilot: React.FC<any> = (props: IAIResponseBlockProps) => {
  const {
    aiFetching,
    aiText,
    aiResultId,
    downloadPath,
    feedbackAttributeName,
    updateAIFeedback,
  } = props;

  const searchPluginInstance = searchPlugin();

  const [selectedFeedback, setSelectedFeedback] = useState<string | null>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const [urlNodePath, setNodePath] = useState<string | null>(null);


  const renderModal = () => (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="filterModal"
    >
      <div className="filterModalContent">
        <div className="filterModalHead">
          <div className="filterModalTitle">
            <h2 id="modal-title">{isModalOpen}</h2>
            <p id="modal-description">
              You can view the content for this search in the viewer below.
            </p>
          </div>
          <div
            className="filterModalClose"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="filterModalBody">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
            <Viewer
              fileUrl={urlNodePath || ""}
              plugins={[searchPluginInstance]}
              defaultScale={1.25} // Adjust zoom level
            />
          </Worker>
        </div>
      </div>
    </Modal>
  );

  const handleFeedbackClick = (feedback: "thumbs_up" | "thumbs_down") => {
    setSelectedFeedback(feedback);
    updateAIFeedback({
      id: aiResultId,
      user_feedback: feedback === "thumbs_up" ? "THUMBS_UP" : "THUMBS_DOWN",
    });
  };

  return (
    <div className="rightSide rightSideContent">
      <div className="rightSideContentBlock">
        <div className="blockHeader">
          <h2>
            <AutoAwesomeOutlinedIcon sx={{ color: "white" }} /> Copilot
          </h2>
        </div>
        <div className="blockBody">
          {aiFetching ? (
            <>
              <Skeleton
                variant="text"
                width="80%"
                height={30}
                sx={{ bgcolor: "#f3f3f39e" }}
              />
              <Skeleton
                variant="text"
                width="100%"
                height={30}
                sx={{ bgcolor: "#f3f3f39e" }}
              />
              <Skeleton
                variant="text"
                width="80%"
                height={30}
                sx={{ bgcolor: "#f3f3f39e" }}
              />
              <Skeleton
                variant="text"
                width="100%"
                height={30}
                sx={{ bgcolor: "#f3f3f39e" }}
              />
            </>
          ) : (
            <>
              <p>{aiText}</p>
              <div className="pdfFilesList">
                {isArrayLength(props.aiResult?.source_nodes)
                  ? renderSourceNodes(
                      props.aiResult?.source_nodes,
                      downloadPath,
                      setModalOpen,
                      setNodePath
                    )
                  : null}
              </div>
            </>
          )}
          {aiResultId && !aiFetching ? (
            <div className="likeDislikeSec">
              <button
                role="button"
                onClick={() => handleFeedbackClick("thumbs_up")}
              >
                {selectedFeedback === "thumbs_up" ? (
                  <ThumbUpAltIcon />
                ) : (
                  <ThumbUpAltOutlinedIcon />
                )}
                This was helpful
              </button>
              <button
                role="button"
                onClick={() => handleFeedbackClick("thumbs_down")}
              >
                {selectedFeedback === "thumbs_down" ? (
                  <ThumbDownAltIcon />
                ) : (
                  <ThumbDownOffAltOutlinedIcon />
                )}
                This wasn’t helpful
              </button>
            </div>
          ) : null}
          {renderModal()}
        </div>
      </div>
    </div>
  );
};

export default SearchViewCoPilot;
