import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createComplianceInsight, updateComplianceInsight } from "../../actions/complianceInsightActions";
import { IComplianceInsight } from "../../stack-shared/interfaces/model/compliance-insight.interface";
import { MARGIN_MEDIUM } from "../Shared/layout-constants";

interface UpsertComplianceInsightFormProps {
  mode: "create" | "update";
  initialValues: any;
  onCreateSuccess: (data: any) => void;
  onUpdateSuccess: (data: any) => void;
}

const UpsertComplianceInsightForm: React.FC<UpsertComplianceInsightFormProps> = ({
  mode,
  initialValues,
  onCreateSuccess,
  onUpdateSuccess,
}) => {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    text: Yup.string().required("Text is required"),
  });

  const handleSubmit = (values: Partial<IComplianceInsight>) => {
    if (mode === "update" && values.id) {
      console.log("dispatch update")
      dispatch(
        updateComplianceInsight(values, (data) => {
          onUpdateSuccess(data);
        })
      );
    } else {
      console.log("dispatch create")
      dispatch(
        createComplianceInsight(values, (data) => {
          onCreateSuccess(data);
        })
      );
    }
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div
        style={{
          width: "80%",
          maxWidth: "500px",
          backgroundColor: "white",
          padding: "20px",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange }) => (
            <Form>
              <Box sx={{ mb: 2 }}> {/* Adds bottom margin */}
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={values.name || ""}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                />
              </Box>
              <Box sx={{ mb: 2 }}> {/* Adds bottom margin */}
                <TextField
                  fullWidth
                  label="Text"
                  name="text"
                  value={values.text || ""}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={10}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "20px", padding: "10px 0", fontSize: "16px" }}
              >
                {mode === "create" ? "Create Compliance Insight" : "Update Compliance Insight"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpsertComplianceInsightForm;
