import React, { useEffect } from "react";
import { useMediaQuery, Drawer, IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

// Define the type for individual filters
interface FilterItem {
  reg: string; // Regulation number
  title: string; // Regulation title
  count: number; // Count of occurrences
}

// Define the props for the component
interface Props {
  facetedNavFilters: FilterItem[]; // Array of filters passed as a prop
  onApplyFilter: (filter: string | null) => void; // Callback when a filter is applied or removed
}

export default function SearchViewFilters({
  facetedNavFilters,
  onApplyFilter,
}: Props) {
  const isMobile = useMediaQuery("(max-width:1024px)");
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // Extract the active filter from the URL
  const queryParams = new URLSearchParams(location.search);
  const activeFilter = queryParams.get("keyword")?.split(",")[1] || null;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleFilterClick = (filter: string) => {
    // If the clicked filter is already active, remove it
    const isActive = activeFilter === filter;
    const newFilter = isActive ? null : filter;

    // Update the URL query parameter
    const baseKeyword = queryParams.get("keyword")?.split(",")[0] || "osha";
    const updatedKeyword = newFilter ? `${baseKeyword},${newFilter}` : baseKeyword;

    queryParams.set("keyword", updatedKeyword);
    navigate(`${location.pathname}?${queryParams.toString()}`);

    // Notify parent about the applied or removed filter
    onApplyFilter(newFilter);
  };

  const filterContent = (
    <div className="leftSide appliedLeftFilters">
      <div className="filterBlock">
        <div className="filterHead">
          <h2 className="filterTitle">Regulations</h2>
        </div>
        <div className="filterBody">
          {facetedNavFilters.map((filter, index) => (
            <button
              key={index}
              onClick={() => handleFilterClick(filter.reg)}
              className={classNames("facetedNavFilters", {
                ["activeFacetedNavFilters"]: activeFilter === filter.reg,
              })}
            >
              {`${filter.reg} - ${filter.title} (${filter.count})`}
            </button>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <>
          <IconButton onClick={handleDrawerToggle}>
            <FilterListIcon /> Filter
          </IconButton>
          <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            sx={{
              "& .MuiDrawer-paper": {
                height: "70%",
                backgroundColor: "#FFFFFF",
                padding: "16px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              },
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                padding: "16px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              {filterContent}
            </div>
          </Drawer>
        </>
      ) : (
        filterContent
      )}
    </>
  );
}
