import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

interface DocumentTypeData {
  documentTypeName: string | null;
  totalCount: string;
  totalMilvusVectors: string;
  totalFileSize: string;
  totalCharCount: string;
  totalSolrIndexed: string;
  totalMilvusIndexed: string;
}

interface IDocumentSummaryTable {
  data: DocumentTypeData[];
}

const DocumentSummaryTable: React.FC<IDocumentSummaryTable> = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>Document Type</strong></TableCell>
            <TableCell align="right"><strong>Total Count</strong></TableCell>
            <TableCell align="right"><strong>Milvus Vectors</strong></TableCell>
            <TableCell align="right"><strong>File Size (bytes)</strong></TableCell>
            <TableCell align="right"><strong>Character Count</strong></TableCell>
            <TableCell align="right"><strong>Solr Indexed</strong></TableCell>
            <TableCell align="right"><strong>Milvus Indexed</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <TableRow key={row.documentTypeName}>
              <TableCell>{row.documentTypeName}</TableCell>
              <TableCell align="right">{row.totalCount}</TableCell>
              <TableCell align="right">{row.totalMilvusVectors}</TableCell>
              <TableCell align="right">{row.totalFileSize}</TableCell>
              <TableCell align="right">{row.totalCharCount}</TableCell>
              <TableCell align="right">{row.totalSolrIndexed}</TableCell>
              <TableCell align="right">{row.totalMilvusIndexed}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentSummaryTable;
