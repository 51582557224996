import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import {
  isArrayLength,
  renderHighlight,
  replaceSectionEntity,
} from "../../constants/genericHelpers";
import { REACT_APP_SERVER_DOMAIN } from "../../constants";
import { Worker, Viewer, PdfJs } from "@react-pdf-viewer/core";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

interface SearchViewCardProps {
  result: {
    id: string;
    reg_num: string;
    part_num: string;
    friendly_title: any;
    friendly_reg_label: string;
    original_file_name: any;
    subpart: string;
    reg_title: string;
    reg_body: string;
    signed_download_url: string;
  };
  guidanceHighlightText: any[];
  caselawHighlightText: any[];
}

interface RenderGuidanceOrCaseLawProps {
  currentRegulationType: "guidance" | "case-law";
  result: {
    friendly_title: string | string[] | undefined;
  };
  toggleModal: () => void;
  toggleTooltip: (e: React.MouseEvent<HTMLButtonElement>) => void;
  renderModal: () => React.ReactNode;
  renderTooltip: () => React.ReactNode;
  guidanceHighlightText: any;
  caselawHighlightText: any;
}

// Helper function to return an icon based on regulation type
const getIconByRegulationType = (type: string): React.ReactNode => {
  const icons: Record<string, React.ReactNode> = {
    regulations: <AccountBalanceOutlinedIcon />,
    guidance: <MenuBookOutlinedIcon />,
    "case-law": <GavelOutlinedIcon />,
  };

  return icons[type.toLowerCase()] || null;
};

const RenderGuidanceOrCaseLaw: React.FC<RenderGuidanceOrCaseLawProps> = ({
  currentRegulationType,
  result,
  toggleModal,
  toggleTooltip,
  renderModal,
  renderTooltip,
  guidanceHighlightText,
  caselawHighlightText,
}) => {
  // Utility to handle titles
  const getFriendlyTitle = (title: string | string[] | undefined) =>
    Array.isArray(title) && title.length > 0 ? title[0] : title || "";

  const normalizedGuidanceHighlightText = Array.isArray(guidanceHighlightText)
    ? guidanceHighlightText.join(" ") || "No highlight text available."
    : guidanceHighlightText || "No highlight text available.";

  const normalizedCaselawHighlightText = Array.isArray(caselawHighlightText)
    ? caselawHighlightText.join(" ") || "No highlight text available."
    : caselawHighlightText || "No highlight text available.";

  // Get highlight text based on regulation type
  const getHighlightText = () => {
    switch (currentRegulationType) {
      case "guidance":
        return normalizedGuidanceHighlightText;
      case "case-law":
        return normalizedCaselawHighlightText;
      default:
        return "";
    }
  };

  // Common JSX structure
  const renderContentBox = (type: "guidance" | "case-law") => (
    <div
      className="middleContentBox appliedFilterContentBox"
      role="button"
      onClick={toggleModal}
    >
      <h2 className="title">
        <strong>{getFriendlyTitle(result?.friendly_title)}</strong>
        <button
          className="iconWrapper brightIconWrapper"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleTooltip(e);
          }}
        >
          <MenuBookOutlinedIcon />
        </button>
      </h2>
      {renderModal()}
      {renderTooltip()}
      <p className="time">
        {new Date().toDateString().split(" ").slice(1).join(" ")}
      </p>
      <p>{type === "guidance" ? "Guidance - PH" : "Case Law - PH"}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: renderHighlight(getHighlightText()),
        }}
      />
    </div>
  );

  // Render content based on regulation type
  if (currentRegulationType === "guidance") {
    return renderContentBox("guidance");
  }
  if (currentRegulationType === "case-law") {
    return renderContentBox("case-law");
  }

  return null; // Default case if no regulation type matches
};

const SearchViewCard: React.FC<SearchViewCardProps> = ({
  result,
  guidanceHighlightText,
  caselawHighlightText,
}) => {
  const location = useLocation();
  const currentPath = location?.pathname?.split("/");
  const currentRegulationType = currentPath?.[currentPath.length - 1];

  const queryParams = new URLSearchParams(location.search);
  const searchedTerm = queryParams.get("keyword");

  const [isExpanded, setIsExpanded] = useState(false);
  const [tooltipAnchor, setTooltipAnchor] = useState<null | HTMLElement>(null);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [pdfReady, setPdfReady] = useState(false);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  // Initialize the search plugin
  const searchPluginInstance = searchPlugin();
  const { highlight } = searchPluginInstance;

  const toggleTooltip = (event: React.MouseEvent<HTMLElement>) => {
    setTooltipAnchor(event.currentTarget);
    setTooltipOpen(!isTooltipOpen);
  };

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
    if (!isModalOpen) {
      // Reset pdfReady only when closing the modal
      setPdfReady(false);
    }
  };

  useEffect(() => {
    // Only highlight if all conditions are met and no infinite loop
    if (isModalOpen && pdfReady && searchedTerm) {
      highlight({
        keyword: searchedTerm,
        matchCase: false,
      });
    }
  }, [isModalOpen, pdfReady, searchedTerm]); // No circular updates here

  const renderModal = () => (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setModalOpen(false);
        setPdfReady(false); // Reset state when modal is closed
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="filterModal"
    >
      <div className="filterModalContent">
        <div className="filterModalHead">
          <div className="filterModalTitle">
            <h2 id="modal-title">{searchedTerm}</h2>
            <p id="modal-description">
              You can view the content for this search in the viewer below.
            </p>
          </div>
          <div
            className="filterModalClose"
            onClick={() => {
              setModalOpen(false);
              setPdfReady(false); // Reset state when modal is closed
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="filterModalBody">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
            <Viewer
              fileUrl={result?.signed_download_url}
              plugins={[searchPluginInstance]}
              defaultScale={1.25} // Adjust zoom level
              onDocumentLoad={() => setPdfReady(true)} // Mark PDF as ready
            />
          </Worker>
        </div>
      </div>
    </Modal>
  );

  const renderTooltip = () => (
    <Popper
      className="customPopover"
      open={isTooltipOpen}
      anchorEl={tooltipAnchor}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
            <div className="customPopoverHead">
              <h2>Related</h2>
            </div>
            <div className="customPopoverBody">
              <div className="customPopoverContent">
                <p>
                  MSHA PC-7014 - Report on 30 CFR Part 50 - Yellow Jacket{" "}
                  <span> - Dec 01 1986</span>
                </p>
                <span className="bookIcon">
                  <MenuBookOutlinedIcon />
                </span>
              </div>
            </div>
          </Box>
        </Fade>
      )}
    </Popper>
  );

  const renderRegulationsContent = () => (
    <div className="middleContentBox appliedFilterContentBox">
      <h2 className="title">
        {result?.reg_title} {getIconByRegulationType(currentRegulationType)}
      </h2>
      <p className="time">{replaceSectionEntity(result?.friendly_reg_label)}</p>
      <p>{result?.subpart}</p>
      <p>
        {isExpanded ? result?.reg_body : `${result?.reg_body?.slice(0, 400)}`}
        {result?.reg_body?.length > 400 && (
          <span
            onClick={() => setIsExpanded((prev) => !prev)}
            className="toggleTextLink"
          >
            {isExpanded ? "Hide" : "Expand"}
          </span>
        )}
      </p>
    </div>
  );

  if (
    currentRegulationType === "guidance" ||
    currentRegulationType === "case-law"
  ) {
    return (
      <RenderGuidanceOrCaseLaw
        currentRegulationType={currentRegulationType as "guidance" | "case-law"}
        result={result}
        toggleModal={toggleModal}
        toggleTooltip={toggleTooltip}
        renderModal={renderModal}
        renderTooltip={renderTooltip}
        guidanceHighlightText={guidanceHighlightText}
        caselawHighlightText={caselawHighlightText}
      />
    );
  }

  if (currentRegulationType === "regulations" && result?.reg_title) {
    return renderRegulationsContent();
  }

  return null;
};

export default SearchViewCard;
