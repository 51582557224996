import React, { useRef, useState } from "react";
import MaterialTable, { Column } from "@material-table/core";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";
import HttpClient from "../../api/HttpClient";
import Loading from "../General/Loading";
import StandardModal from "../Shared/StandardModal";
import { dateFormatted } from "../../utils/date-utils";
import { tableIcons } from "../Shared/MaterialTableShared";
import { ToggleOn, ToggleOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateBasicUserInfo } from "../../actions/userActions";

const client = new HttpClient();

interface IUserTable {
  title: string;
  fixed_query_params: Record<string, any>;
  onEditUser: (user: any) => void;
}

interface RowData {
  id: number;
  user_email: string;
  display_name: string;
  wp_role: string;
  user_registered: string;
  active: boolean;
  is_admin: number;
  is_active: boolean;
  user_status: number;
  user_activation_key?: string;
}

const UserTable: React.FC<IUserTable> = ({ title, fixed_query_params }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState<RowData | null>(
    null
  );
  const [tableKey, setTableKey] = useState(0);
  const [activationModalOpen, setActivationModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [toggleStates, setToggleStates] = useState<Record<number, boolean>>({}); // Track temporary toggle states
  const tableRef = useRef<any>(null);

  const toggleUserAccess = (user: RowData) => {
    // Temporarily update toggleStates to reflect the action immediately
    setToggleStates((prev) => ({
      ...prev,
      [user.id]: !user.is_active,
    }));

    setSelectedUserData(user);
    setActivationModalOpen(true);
  };

  const handleMenuItemClick = (role: "Admin" | "User") => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const refreshTableData = async () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange(); // Trigger a table data refresh
    }

    // Optionally clear toggleStates after refresh is complete
    setTimeout(() => {
      setToggleStates({});
      console.log("toggleStates cleared after refresh");
    }, 500); // Delay clearing to ensure smooth state synchronization
  };

  const fetchUsers = async (query: any) => {
    const params: Record<string, any> = {
      take: query.pageSize,
      skip: query.page * query.pageSize,
      search: query.search,
      ...fixed_query_params,
    };

    if (query.orderBy) {
      params.orderBy = query.orderBy.field;
    }
    if (query.orderDirection) {
      params.orderDirection = query.orderDirection;
    }

    const result = await client.get("api/user/query", params);
    return {
      data: result.data.data.map((user: RowData) => ({
        ...user,
        is_active: user.is_active === true,
      })),
      page: query.page,
      totalCount: result.data.total,
    };
  };

  const columns: Column<RowData>[] = [
    {
      title: "id",
      field: "id",
      sorting: true,
    },
    {
      title: "Email",
      field: "user_email",
      sorting: true,
    },
    {
      title: "Display Name",
      field: "display_name",
      sorting: true,
    },
    {
      title: <span style={{ cursor: "pointer" }}>WordPress Role</span>,
      field: "wp_role",
      sorting: false,
      render: (row) => {
        const isAdmin = row.is_admin === 1;
        return (
          <span
            style={{
              color: isAdmin ? "#0690c6" : "#f66e2f",
              cursor: "pointer",
            }}
          >
            {isAdmin ? "Admin" : "User"}
          </span>
        );
      },
    },
    {
      title: "User Registered",
      field: "user_registered",
      sorting: true,
      render: (row) => (
        <span>{dateFormatted(row.user_registered, "yyyymmdd")}</span>
      ),
    },
    {
      title: "Status",
      field: "active",
      render: (row) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => toggleUserAccess(row)}
          >
            {row.is_active ? (
              <ToggleOn style={{ color: "#0690c6", marginRight: "8px" }} />
            ) : (
              <ToggleOff style={{ color: "#f66e2f", marginRight: "8px" }} />
            )}
            <span>{row.is_active ? "Active" : "Inactive"}</span>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Loading loading={processing} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick("Admin")}>Admin</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("User")}>User</MenuItem>
      </Menu>
      <StandardModal
        title="Activation Modal"
        open={activationModalOpen}
        onClose={() => setActivationModalOpen(false)}
        paragraphs={[
          `Are you sure you want to ${
            selectedUserData?.is_active ? "deactivate" : "activate"
          } the user with email "${selectedUserData?.user_email}"?`,
        ]}
        actions={[
          {
            title: "Cancel",
            callback: () => setActivationModalOpen(false),
          },
          {
            title: "Yes",
            callback: async () => {
              if (!selectedUserData) return;
          
              setProcessing(true);
          
              try {
                const updatedStatus = !selectedUserData.is_active;
          
                const payload = {
                  display_name: selectedUserData.display_name,
                  user_email: selectedUserData.user_email,
                  is_active: updatedStatus,
                };
          
                // API call to update user status
                await dispatch(updateBasicUserInfo(payload) as any);
          
                enqueueSnackbar(
                  `User ${updatedStatus ? "activated" : "deactivated"} successfully.`,
                  { variant: "success" }
                );
          
                // Refresh the table data to reflect changes
                await refreshTableData();
          
                // Clear the modal and processing state
                setActivationModalOpen(false);
              } catch (error) {
                enqueueSnackbar("Failed to update user status.", {
                  variant: "error",
                });
              } finally {
                setProcessing(false);
              }
            },
          }
          
        ]}
      />

      <div style={{ display: "block" }}>
        <MaterialTable
          key={tableKey}
          tableRef={tableRef}
          icons={tableIcons}
          title={title}
          columns={columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              (async () => {
                try {
                  const result = await fetchUsers(query);
                  resolve(result);
                } catch (error) {
                  reject(error);
                }
              })();
            })
          }
          options={{
            sorting: true,
            search: false,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            paginationAlignment: "left",
          }}
        />
        ;
      </div>
    </div>
  );
};

export default UserTable;
