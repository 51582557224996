import { Button, Divider, FormControlLabel, Switch, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../General/Loading";
import { useDispatch, useSelector } from "react-redux";
import { doFullIndex, getSystemSettings, updateSystemSettings } from "../../actions/adminActions";
import { IMainState, IRootState } from "../../interfaces/rootState";
import { useSnackbar } from "notistack";
import { DOCUMENT_TYPE } from "../../stack-shared/constants/document.constants";
import { getDocumentReferenceTypeArray } from "../../store/selectors/main";
import DocumentTypeEntityExtractionForm from "./DocumentTypeEntityExtractionForm";

interface IDocumentManagement {

}

export const FULL_INDEX_TEMPLATE = {
    create_document_types: {
    enabled: false,
    document_types: [],
  },
  solr_create_collections: {
    enabled: false,
    collections: [],
  },
  solr_bootstrap: {
    enabled: false,
    do_field_types: false,
    document_types: [],
    do_regulations: false,
  },
  solr_batch_index: {
    enabled: false,
    limit: 99999,
    max_fails: 99999,
    force: false,
    document_ids: [],
    collections: [],
  },
  milvus_bootstrap: {
    enabled: false,
    collections: [],
  },
  batch_vectorize: {
    enabled: false,
    limit: 99999,
    max_fails: 99999,
    force: false,
    document_ids: [],
    collections: [],
  },
  batch_index_regulations: {
    enabled: false,
    limit: 99999,
  },
  extract_entities: {
    enabled: false,
    facet_limit: 99999,
    doc_limit: 99999,
    doc_ids: [],
    force: false,
    clean_ignore_list: false,
    document_types: [],
  },
}

const DocumentManagement: React.FC<IDocumentManagement> = (props) => {


    const documentTypes = useSelector(getDocumentReferenceTypeArray);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [fuzzyFactor, setFuzzyFactor] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [mshaIgnoreListPerson, setMshaIgnoreListPerson] = useState("");
    const [mshaIgnoreListLocation, setMshaIgnoreListLocation] = useState("");
    const [mshaIgnoreListOrganization, setMshaIgnoreListOrganization] = useState("");
    const [mshaIncludeListPerson, setMshaIncludeListPerson] = useState("");
    const [mshaIncludeListLocation, setMshaIncludeListLocation] = useState("");
    const [mshaIncludeListOrganization, setMshaIncludeListOrganization] = useState("");
    const [caseLawIgnoreListPerson, setCaseLawIgnoreListPerson] = useState("");
    const [caseLawIgnoreListLocation, setCaseLawIgnoreListLocation] = useState("");
    const [caseLawIgnoreListOrganization, setCaseLawIgnoreListOrganization] = useState("");
    const [caseLawIncludeListPerson, setCaseLawIncludeListPerson] = useState("");
    const [caseLawIncludeListLocation, setCaseLawIncludeListLocation] = useState("");
    const [caseLawIncludeListOrganization, setCaseLawIncludeListOrganization] = useState("");


    useEffect(() => {
        setLoading(true)
        dispatch(getSystemSettings({}, (data) => {
            setFuzzyFactor(data.system_settings.fuzzy_factor || 0)
            setMshaIgnoreListPerson(data.system_settings.msha_person_ignore_list || "")
            setMshaIgnoreListLocation(data.system_settings.msha_location_ignore_list || "")
            setMshaIgnoreListOrganization(data.system_settings.msha_organization_ignore_list || "")
            setCaseLawIgnoreListPerson(data.system_settings.case_law_person_ignore_list || "")
            setCaseLawIgnoreListLocation(data.system_settings.case_law_location_ignore_list || "")
            setCaseLawIgnoreListOrganization(data.system_settings.case_law_organization_ignore_list || "")

            setMshaIncludeListPerson(data.system_settings.msha_person_include_list || "")
            setMshaIncludeListLocation(data.system_settings.msha_location_include_list || "")
            setMshaIncludeListOrganization(data.system_settings.msha_organization_include_list || "")
            setCaseLawIncludeListPerson(data.system_settings.case_law_person_include_list || "")
            setCaseLawIncludeListLocation(data.system_settings.case_law_location_include_list || "")
            setCaseLawIncludeListOrganization(data.system_settings.case_law_organization_include_list || "")

            setLoading(false)
        }));
    }, [])

    const systemSettings = useSelector((state: any) => state.mainState.system_settings);

    useEffect(() => {
        console.log("change in systemSettings", systemSettings)
    }, [systemSettings])

    const handleFullIndex = (type: string) => {

        let modified_full_index = { ...FULL_INDEX_TEMPLATE }
        switch (type) {
            // case 'upsert_document_reference_msha':
            //     modified_full_index.upsert_document_reference.msha = true;
            //     break;
            // case 'upsert_document_reference_case_law':
            //     modified_full_index.upsert_document_reference.case_law = true;
            //     break;
            // case 'batch_download_msha':
            //     modified_full_index.batch_download_msha.enabled = true;
            //     break;
            // case 'batch_download_case_law':
            //     modified_full_index.batch_download_case_law.enabled = true;
            //     break;
            // case 'batch_index_quick_facts':
            //     modified_full_index.batch_index_quick_facts.enabled = true;
            //     break;
            // case 'batch_index_regulations':
            //     modified_full_index.batch_index_regulations.enabled = true;
            //     break;
            // case 'extract_entities_msha':
            //     modified_full_index.extract_entities_msha.enabled = true;
            //     break;
            // case 'extract_entities_case_law':
            //     modified_full_index.extract_entities_case_law.enabled = true;
            //     break
            default:
                break;
        }
        setLoading(true)
        dispatch(doFullIndex({ modified_full_index }, () => {
            setLoading(false)
        }, () => {
            setLoading(false)
        }, type + " action performed", true, true) as any);
    }

    // Handler for fuzzy factor change
    const handleFuzzyFactorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Here you could dispatch an action to update the fuzzy factor in your store/system settings
        console.log("handle fuzzy factor change", event.target.value); // Example action
        if (isNaN(Number(event.target.value)) || Number(event.target.value) < 0 || Number(event.target.value) > 5) {
            enqueueSnackbar("Value between 0 and 5 required, 0 is disabled", { variant: "error" });
        }
        else {
            setFuzzyFactor(Number(event.target.value))
        }
    };

    // Handler for fuzzy factor change
    const submitFuzzyFactor = () => {
        setLoading(true)
        dispatch(updateSystemSettings({ fuzzy_factor: fuzzyFactor }, () => { setLoading(false) }, () => { }, "Fuzzy Factor Changed", true));

    };

    const submitListsMSHA = () => {

        if (isValidCommaSeparatedArray(mshaIgnoreListPerson) === false) {
            enqueueSnackbar("MSHA person ignore list is invalid", { variant: "error" });
            return;
        }
        if (isValidCommaSeparatedArray(mshaIgnoreListLocation) === false) {
            enqueueSnackbar("MSHA location ignore list is invalid", { variant: "error" });
            return;
        }
        if (isValidCommaSeparatedArray(mshaIgnoreListOrganization) === false) {
            enqueueSnackbar("MSHA organization ignore list is invalid", { variant: "error" });
            return;
        }

        if (isValidCommaSeparatedArray(mshaIncludeListPerson) === false) {
            enqueueSnackbar("MSHA person include list is invalid", { variant: "error" });
            return;
        }
        if (isValidCommaSeparatedArray(mshaIncludeListLocation) === false) {
            enqueueSnackbar("MSHA location include list is invalid", { variant: "error" });
            return;
        }
        if (isValidCommaSeparatedArray(mshaIncludeListOrganization) === false) {
            enqueueSnackbar("MSHA organization include list is invalid", { variant: "error" });
            return;
        }

        setLoading(true)
        dispatch(updateSystemSettings({  
            msha_person_ignore_list: mshaIgnoreListPerson,
            msha_location_ignore_list: mshaIgnoreListLocation,
            msha_organization_ignore_list: mshaIgnoreListOrganization,
            msha_person_include_list: mshaIncludeListPerson,
            msha_location_include_list: mshaIncludeListLocation,
            msha_organization_include_list: mshaIncludeListOrganization
         }, () => { setLoading(false) }, () => { }, "Ignore Lists Updated", true));

    };

    const submitListsCaseLaw = () => {
        
        if (isValidCommaSeparatedArray(caseLawIgnoreListPerson) === false) {
            enqueueSnackbar("Case Law person ignore list is invalid", { variant: "error" });
            return;
        }
        if (isValidCommaSeparatedArray(caseLawIgnoreListLocation) === false) {
            enqueueSnackbar("Case Law location ignore list is invalid", { variant: "error" });
            return;
        }
        if (isValidCommaSeparatedArray(caseLawIgnoreListOrganization) === false) {
            enqueueSnackbar("Case Law organization ignore list is invalid", { variant: "error" });
            return;
        }

        if (isValidCommaSeparatedArray(caseLawIncludeListPerson) === false) {
            enqueueSnackbar("Case Law person include list is invalid", { variant: "error" });
            return;
        }
        if (isValidCommaSeparatedArray(caseLawIncludeListLocation) === false) {
            enqueueSnackbar("Case Law location include list is invalid", { variant: "error" });
            return;
        }
        if (isValidCommaSeparatedArray(caseLawIncludeListOrganization) === false) {
            enqueueSnackbar("Case Law organization include list is invalid", { variant: "error" });
            return;
        }


        setLoading(true)
        dispatch(updateSystemSettings({ 
            case_law_person_ignore_list: caseLawIgnoreListPerson,
            case_law_location_ignore_list: caseLawIgnoreListLocation,
            case_law_organization_ignore_list: caseLawIgnoreListOrganization,
            case_law_person_include_list: caseLawIncludeListPerson,
            case_law_location_include_list: caseLawIncludeListLocation,
            case_law_organization_include_list: caseLawIncludeListOrganization
         }, () => { setLoading(false) }, () => { }, "Ignore Lists Updated", true));

    };

    const cleanIgnoreListForDocType = (doc_type: DOCUMENT_TYPE) => {
        let modified_full_index = { ...FULL_INDEX_TEMPLATE }
        switch (doc_type.toUpperCase()) {
            case DOCUMENT_TYPE.MSHA:
                // modified_full_index.extract_entities_msha.enabled = true;
				// modified_full_index.extract_entities_msha.doc_limit = 0;
				// modified_full_index.extract_entities_msha.clean_ignore_list = true;
                break;
            case DOCUMENT_TYPE.CASE_LAW:
                // modified_full_index.extract_entities_case_law.enabled = true;
				// modified_full_index.extract_entities_case_law.doc_limit = 0;
				// modified_full_index.extract_entities_case_law.clean_ignore_list = true;
                break;
			default:
				console.log("Bad document type");
				break;
        }
        setLoading(true)
		console.log('modified_full_index', modified_full_index)
        dispatch(doFullIndex(modified_full_index, () => {
            setLoading(false)
        }, () => {
            setLoading(false)
        }, "Entity extraction complete", true, true) as any);
	}

    // Handler for use n-gram switch change
    const handleUseNGramChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        // Here you could dispatch an action to update the use_edge_n_grams in your store/system settings
        console.log("handle_user_n_grams", checked); // Example action
        setLoading(true)
        dispatch(updateSystemSettings({ use_edge_n_grams: checked }, () => {
            setLoading(false)
        }, () => { }, "Use Edge N Gram Toggled", true));
    };

    const isValidCommaSeparatedArray = (str: any) => {
        // Accept an empty string as valid input
        if (str.length === 0) {
          return true;
        }
      
        // Check if the string only contains letters, numbers, commas, optional spaces, periods, and ampersands
        const isValidPattern = /^[\w\s,&\.]*$/.test(str);
        if (!isValidPattern) {
          console.log(`Invalid characters found in string: "${str}". Only letters, numbers, commas, spaces, periods, and ampersands are allowed.`);
          return false;
        }
      
        // Split the string by commas and trim spaces from each element
        const elements = str.split(',').map((element: any) => element.trim());
      
        // Optional: Check if all elements are non-empty after trimming
        // This line can be adjusted or removed based on whether you want to treat
        // strings like "entity1, , entity2" as valid or not
        const allElementsValid = elements.every((element: any, index: any) => {
          const isValidElement = element.length > 0;
          if (!isValidElement) {
            console.log(`Invalid element found: Element at index ${index} is empty after trimming.`);
          }
          return isValidElement;
        });
      
        if (!allElementsValid) {
          return false;
        }
      
        return true;
    };

    console.log("systemSettings", systemSettings)


    return (<div>
        <Loading loading={loading} />
        {/* <h1>Document Management</h1>
        <div style={{ margin: 16 }}>
            <Button onClick={() => handleFullIndex('upsert_document_reference_msha')} variant="outlined">Update Doc Reference MSHA</Button>
        </div>
        <div style={{ margin: 16 }}>
            <Button onClick={() => handleFullIndex('upsert_document_reference_case_law')} variant="outlined">Update Doc Reference Case Law</Button>
        </div>
        <div style={{ margin: 16 }}>
            <Button onClick={() => handleFullIndex('batch_download_msha')} variant="outlined">Download MSHA</Button>
        </div>
        <div style={{ margin: 16 }}>
            <Button onClick={() => handleFullIndex('batch_download_case_law')} variant="outlined">Download Case Law</Button>
        </div>
        <div style={{ margin: 16 }}>
            <Button onClick={() => handleFullIndex('batch_index_quick_facts')} variant="outlined">Index Quick Facts</Button>
        </div>
        <div style={{ margin: 16 }}>
            <Button onClick={() => handleFullIndex('batch_index_regulations')} variant="outlined">Index Regulations</Button>
        </div>
        <Divider /> */}
        <h3>Entity Extraction</h3>
<p>Ignore list will ignore the term even if the NLP model finds it, this is useful for false positives.</p>
<p>The include list will include the term if its present, even if the NLP model does not detect it.</p>

{documentTypes.map((docType) => {
    return (
        <>
            <DocumentTypeEntityExtractionForm documentType={docType} />
            <br/>
            <Button style={{ marginRight: 16 }} onClick={() => cleanIgnoreListForDocType(docType.name! as any)} variant="outlined">Clean {docType.name!} Ignored Facets</Button>
        </>
    )
})}

  
        <Divider />

        {/* <div style={{ margin: 16 }}>
            <TextField
                label="Fuzzy Factor"
                type="number"
                value={fuzzyFactor}
                onChange={handleFuzzyFactorChange}
                variant="outlined"
                inputProps={{
                    min: "0",
                    max: "5"
                }}
            />
        </div> */}
        {/* <div style={{ margin: 16 }}>
            <Button onClick={() => submitFuzzyFactor()} variant="outlined">Submit Fuzzy Change</Button>
        </div> */}
        {/* <div style={{ margin: 16 }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={systemSettings?.use_edge_n_grams || false}
                        onChange={handleUseNGramChange}
                        name="useEdgeNGrams"
                        color="primary"
                    />
                }
                label="Use Edge N-Grams"
            />
        </div> */}

    </div>)
}

export default DocumentManagement;