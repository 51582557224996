import { Button } from "@mui/material/";
import Typography from "@mui/material/Typography";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userAction } from "../../actions/userActions";
import { IuserPost } from "../../interfaces/userPost";
import "./Signup.css";
import { SignupField } from "./SignupField";

export interface Props {
  userInfo: any;
}

export interface Values {
  firstName: string;
  lastName: string;
  email: string;
}

export interface State {
  changed: boolean;
  captcha: boolean;
}

class Signup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      changed: false,
      captcha: false,
    };
  }

  public newUser(values: any) {
    this.props.userInfo({ name: values.name, email: values.email });
    this.setState({
      changed: true,
    });
  }

  public onChange(values: any) {
    if (values) {
      this.setState({
        captcha: true,
      });
    }
  }

  public render() {
    const { captcha, changed } = this.state;
    if (changed) {
      return <Navigate replace to="/thankyou" />;
    }

    return (
      <div className="SignUpContainer">
        <div className="spacing" />
        <Formik
          initialValues={{ firstName: "", lastName: "", email: "" }}
          onSubmit={(values) => {
            this.newUser(values);
          }}
        >
          {({ values }) => (
            <Form className="fixedWidthContainer">
              <div className="formHead">
                <h2>MSHAwise</h2>{" "}
                <Typography className="firstText">
                  Your edge to navigating MSHA Regulations.
                </Typography>
              </div>
              <div className="FormContainer">
                {" "}
                <Typography className="secondText">
                  Create your account below. You will receive a welcome email to
                  set your password in the next few minutes to get you going!
                </Typography>
                <div className="fields">
                  <label htmlFor="name">Name</label>
                  <Field
                    name="name"
                    placeholder="Name"
                    component={SignupField}
                  />
                </div>
                <div className="fields">
                  <label htmlFor="email">Email</label>
                  <Field
                    name="email"
                    placeholder="Email"
                    component={SignupField}
                  />
                </div>
                <div className="captcha">
                  <ReCAPTCHA
                    sitekey="6LfBy8IUAAAAAIJ5pK4o3RmNr1ZmldytIlAAK4lK"
                    onChange={this.onChange}
                  />
                </div>
                <Button
                  disabled={!captcha || !values.email}
                  className="signinButton"
                  type="submit"
                  sx={{
                    borderRadius: "5px",
                    display: "flex",
                    width: "100%",
                    padding: "10px 16px",
                    backgroundColor: " #F66E2F",
                    color: "#ffffff",
                    textTransform: "none",
                    boxShadow: "0px 1px 5px 0px #0000001F",
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "#c0c0c0",
                    },
                  }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  userInfo: (userInfo: IuserPost, onSuccess = null, onFail = null) =>
    dispatch(userAction(userInfo, onSuccess, onFail)),
});

export default connect(null, mapDispatchToProps)(Signup);
