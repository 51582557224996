import React from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward"; // Replace with the actual import path if different
import { useNavigate } from "react-router-dom"; // For React Router, or use `Link` for Next.js routing

interface SearchItem {
  label: string;
  url: string;
}

const suggestedSearches: SearchItem[] = [
  { label: 'OSHA', url: 'osha' },
  { label: 'Green Tobacco Sickness', url: 'green-tobacco-sickness' },
  { label: 'Suspended loads', url: 'suspended-loads' },
  { label: 'What are the minimum grades for berms and guardrails?', url: 'what-are-the-minimum-grades-for-berms-and-guardrails' },
  { label: 'What do vacuum samplers do', url: 'what-do-vacuum-samplers-do' },
  { label: 'What are the regulations around berms and guardrails?', url: 'what-are-the-regulations-around-berms-and-guardrails' }
];


const SearchSection: React.FC<{ title: string; searches: SearchItem[] }> = ({
  title,
  searches,
}) => {
  const navigate = useNavigate(); // For navigation, assuming you're using react-router-dom

  const handleSearchClick = (url: string) => {
    navigate(`/search/regulations?keyword=${url}`);
  };

  return (
    <div className="suggestedSearchSec">
      <h2>{title}</h2>
      <div className="suggestedSearchList">
        {searches.map((search, index) => (
          <div
            key={index}
            className="suggestedSearch"
            onClick={() => handleSearchClick(search.url)}
          >
            <ArrowOutwardIcon /> {search.label}
          </div>
        ))}
      </div>
    </div>
  );
};

const SuggestedAndPopularSearches: React.FC = () => {
  return (
    <>
      <SearchSection title="Suggested searches" searches={suggestedSearches} />
    </>
  );
};

export default SuggestedAndPopularSearches;
