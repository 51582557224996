import React, { useEffect, useState } from 'react';
import MaterialTable, { Query } from '@material-table/core';
import { useDispatch, useSelector } from 'react-redux';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import ChatIcon from '@mui/icons-material/VoiceChatRounded'
import IngestIcon from '@mui/icons-material/InsertPageBreak';
import ViewSolrDoc from '@mui/icons-material/DocumentScannerTwoTone';
import ViewVectors from '@mui/icons-material/Polyline';
import EditDocIcon from '@mui/icons-material/Edit';
import ApproveIcon from '@mui/icons-material/ThumbUp';
import DeclineIcon from '@mui/icons-material/ThumbDown';
import TrashIcon from '@mui/icons-material/Delete';
import Loading from '../General/Loading';
import StandardModal from '../Shared/StandardModal';
import { renderTextEllipsisClipboard, tableIcons } from '../Shared/MaterialTableShared';
import { COLORS, MARGIN_LARGE, MARGIN_MEDIUM } from '../Shared/layout-constants';
import { deleteDocumentReference, downloadUploadS3, ingestDocument, milvusGetById, updateDocumentReference, vectorizeDocument } from '../../actions/documentActions';
import { IRootState } from '../../interfaces/rootState';
import { Tooltip, Box, TextField, Button, Switch, FormControlLabel } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { searchAIAction } from '../../actions/aiActions';
import { solrGetDocumentById } from '../../actions/solrActions';
import { FULL_INDEX_TEMPLATE } from './DocumentManagement';
import { doFullIndex } from '../../actions/adminActions';
import { REACT_APP_SERVER_DOMAIN } from '../../constants';
import { DOCUMENT_TYPE } from '../../stack-shared/constants/document.constants';
import AddIcon from '@mui/icons-material/Add';
import UpsertDocumentForm from '../Document/UpsertDocumentForm';
import { getDocumentReferenceTypeArray } from '../../store/selectors/main';
import { showInfoMessage, startLoading, stopLoading } from '../../actions/genericAction';
import { LoopOutlined } from '@mui/icons-material';
import { FOOTER_HEIGHT, SIDEBAR_WIDTH } from '../../constants/layout-enums';
import useWindowSize from '../../hooks/window-resize';

const client = new HttpClient();
interface IMSHADocumentTable {
	doc_type: string,
	title: string
	extra_columns?: any[]
}

const iconStyle = {
	color: COLORS.BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}

const DocumentTable: React.FC<IMSHADocumentTable> = (props) => {

	const dispatch = useDispatch();
	const [processing, setProcessing] = useState(false);
	const [selectedDocumentData, setSelectedDocumentData] = useState({} as any)
	const [activationModalOpen, setActivationModalOpen] = useState(false)
	const [userAdminStatusModalOpen, setUserAdminStatusModalOpen] = useState(false);
	const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

	const [ingestSolr, setIngestSolr] = useState(true);
	const [ingestEntityExtraction, setIngestEntityExtraction] = useState(true);
	const [ingestVectorize, setIngestVectorize] = useState(true);

	const [vectorizeModalOpen, setVectorizeModalOpen] = useState(false);
	const [vectorizeDocumentResult, setVectorizeDocumentResult] = useState({});

	const [indexDocumentModalOpen, setIndexDocumentModalOpen] = useState(false);
	const [indexDocumentResult, setIndexDocumentResult] = useState({});

	const [orderBy, setOrderBy] = useState({tableKey: 0, value: null});
	const [orderByDirection, setOrderByDirection] = useState({tableKey: 0, value: null});

	const [page, setPage] = useState(0);

	const [importDocumentReferenceModalOpen, setImportDocumentReferenceModalOpen] = useState(false);
	const [upsertDocumentReferenceMode, setUpsertDocumentReferenceMode] = useState('create');
	const [upsertDocumentReferenceModalOpen, setUpsertDocumentReferenceModalOpen] = useState(false);

	const [downloadDocFileModalOpen, setDownloadDocFileModalOpen] = useState(false);

	const [entityExtractionModalOpen, setEntityExtractionModalOpen] = useState(false);

	const socketId = useSelector((state: IRootState) => state.mainState.socketId);
	const [searchTerm, setSearchTerm] = useState('');
	
	const [aiSearchModalOpen, setAiSearchModalOpen] = useState(false);
	const [aiResult, setAiResult] = useState({});


	const [tableKey, setTableKey] = useState(0);
	const { title } = props;

	const { width, height } = useWindowSize(500);

	const indexMessages = useSelector((state: IRootState) => state.mainState.adminMessages.ingest);

	console.log("adminMessages here", indexMessages)

	// const documentReferencesRaw = useSelector(getDocumentReferenceArray)
	// const docType = documentReferenceTypes.find((x) => x.name === props.doc_type.toLowerCase());
	// const documentReferences = documentReferencesRaw.filter((x: any) => x.document_type_id === docType?.id);
	// useEffect(() => {
	// 	refreshData();
	// }, [])

	const refreshData = () => {
		setTableKey(tableKey + 1)
		// setProcessing(true)
		// dispatch(getDocumentReferences({
		// 	collection: props.doc_type.toLowerCase()
		// }, (data) => {
		// 	setTableKey(tableKey + 1)
		// 	setProcessing(false)
		// }, () => {
		// 	setProcessing(false)
		// }))
	}

	const extractEntitiesFromDocument = () => {

		let modified_full_index = { ...FULL_INDEX_TEMPLATE }
		switch (props.doc_type.toUpperCase()) {
			case DOCUMENT_TYPE.MSHA:
				// modified_full_index.extract_entities_msha.enabled = true;
				// modified_full_index.extract_entities_msha.document_ids = [selectedDocumentData.id];
				break;
			case DOCUMENT_TYPE.CASE_LAW:
				// modified_full_index.extract_entities_case_law.enabled = true;
				// modified_full_index.extract_entities_case_law.document_ids = [selectedDocumentData.id];
				break;
			default:
				console.log("Bad document type");
				break;
		}
		setProcessing(true)
		dispatch(doFullIndex(modified_full_index, () => {
			setProcessing(false)
			setEntityExtractionModalOpen(false)
			refreshData();
		}, () => {
			setProcessing(false)
			setEntityExtractionModalOpen(false)
		}, "Entity extraction complete", true, true) as any);
	}

	const handleTableDataQuery = async (query: Query<object>): Promise<any> => {

		const params = {
		  take: query.pageSize,
		  skip: page * query.pageSize,
		  collection: props.doc_type.toLowerCase(),
		} as any;
	
		if (!params.search) {
		  params.search = query.search || '';
		}
	
		if (query.orderDirection) {
		  params.orderDirection = query.orderDirection;
		}

		if (query.orderByCollection?.length > 1){
			//console.log("initial load")
			// intial load or no ordering
			if (orderBy.value){
				//console.log("use the state", orderBy.value, orderByDirection.value)
				params.orderBy = orderBy.value;  
				params.orderByDirection = orderByDirection.value;
				params.orderDirection = orderByDirection.value;
			}
		}
		else if (query.orderByCollection[0]) {
			params.orderBy = columns[query.orderByCollection[0].orderBy].field;
			params.orderByDirection = query.orderByCollection[0].orderDirection;
			params.orderDirection = query.orderByCollection[0].orderDirection;
			setOrderBy({tableKey: tableKey, value: params.orderBy})
			setOrderByDirection({tableKey: tableKey, value: params.orderByDirection})
			//console.log("set the state from the query", params.orderBy, params.orderByDirection)
		}
	
		let url = `api/document/search`;
	
		try {
		  const result = await client.post(url, { ...params });
	
		  if (!result.data) throw new Error('Un-Authorized');

		  return {
			data: result.data.list,
			page: page, // Always return the current state `page`
			totalCount: result.data.count,
		  };
		} catch (error) {
		  return error;
		}
	  };

	const handleUpdateDocumentReference = () => {

		let modified_full_index = { ...FULL_INDEX_TEMPLATE }
		dispatch(startLoading({}))

		const methods = []
		if (ingestSolr) {
			methods.push('solr')
		}
		if (ingestEntityExtraction) {
			methods.push('entity-extraction')
		}
		if (ingestVectorize) {
			methods.push('vectorize')
		}
		if (methods.length > 0) {
			dispatch(ingestDocument({
				id: selectedDocumentData.id,
				methods: methods //, 
			}, () => {
				dispatch(stopLoading({}))
				//setImportDocumentReferenceModalOpen(false)
				refreshData();
			}, () => {
				dispatch(stopLoading({}))
			}))
		}
	}

	const downloadDocFile = () => {
		let modified_full_index = { ...FULL_INDEX_TEMPLATE }
		setProcessing(true)
		console.log('modified_full_index', modified_full_index)
		dispatch(doFullIndex(modified_full_index, () => {
			setProcessing(false)
			setDownloadDocFileModalOpen(false)
			refreshData();
		}, () => {
			setProcessing(false)
			setDownloadDocFileModalOpen(false)
		}, "Download complete", true, true) as any);
	}

	let columns = [] as any[]

	columns = columns.concat([
		{
			title: 'Id', field: 'id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Solr Status', field: 'solr_status', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Milvus Status', field: 'milvus_status', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'File Name', field: 'original_file_name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (rowData: any) => renderTextEllipsisClipboard(rowData.original_file_name, 50, dispatch, showInfoMessage),
		},
		{
			title: 'Title', field: 'friendly_title', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (rowData: any) => renderTextEllipsisClipboard(rowData.friendly_title, 50, dispatch, showInfoMessage),
		},
	])

	if (props.extra_columns) {
		columns = columns.concat(props.extra_columns)
	}

	columns = columns.concat([
		{
			title: 'Entities', field: 'milvus_vectors', cellStyle: {
				width: 300,
				maxWidth: 300
			},
			sorting: false,
			render: (row: any) => {
				return <span>
					{row.entity_person?.length || 0}/{row.entity_location?.length || 0}/{row.entity_organization?.length || 0}
				</span>
			}
		},
		{
			title: "Actions",
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						{row.scrape_approved && <>
							<Tooltip title={"Ingest Document Data"} placement="top">
								<IngestIcon
									style={iconStyle}
									onClick={() => {
										setSelectedDocumentData(row)
										setImportDocumentReferenceModalOpen(true)
									}}
								/>
							</Tooltip>


							<Tooltip title={"Solr View"} placement="top">
								<ViewSolrDoc
									style={iconStyle}
									onClick={() => {
										setSelectedDocumentData(row)
										setIndexDocumentResult({})
										setIndexDocumentModalOpen(true)
										setProcessing(true)
										dispatch(solrGetDocumentById({
											collection: props.doc_type.toLowerCase(),
											id: row.id
										}, (data) => {
											setIndexDocumentResult(data)
											setProcessing(false)
										}, () => { }, `${row.friendly_title} Solr Fetched`))
										//
									}}
								/>
							</Tooltip>


							<Tooltip title={"View Vectors"} placement="top">
								<ViewVectors
									style={iconStyle}
									onClick={() => {
										setSelectedDocumentData(row)
										setVectorizeDocumentResult({})
										setVectorizeModalOpen(true)
										dispatch(milvusGetById({
											id: row.id,
											collection: props.doc_type.toLowerCase()
										}, (data) => {
											setVectorizeDocumentResult(data)
										}))

										//
									}}
								/>
							</Tooltip>

							<Tooltip title={"Edit"} placement="top">
								<EditDocIcon
									style={iconStyle}
									onClick={() => {
										setSelectedDocumentData(row)
										setUpsertDocumentReferenceModalOpen(true)
										setUpsertDocumentReferenceMode('update')
										//
									}}
								/>
							</Tooltip>
						</>}

						{!row.scrape_approved && <>
							<Tooltip title={"Decline"} placement="top">
								<DeclineIcon
									style={iconStyle}
									onClick={() => {
										dispatch(deleteDocumentReference({
											id: row.id
										}, () => {
											refreshData();
										}))
									}}
								/>
							</Tooltip>
							<Tooltip title={"Approve"} placement="top">
								<ApproveIcon
									style={{ ...iconStyle, marginLeft: 10 }}
									onClick={() => {
										dispatch(updateDocumentReference({
											id: row.id,
											scrape_approved: true
										}, () => {
											refreshData();
										}))
									}}
								/>
							</Tooltip>
						</>}
						<Tooltip title={"Sync to S3"} placement="top">
							<LoopOutlined
								style={iconStyle}
								onClick={() => {
									setSelectedDocumentData(row)
									dispatch(downloadUploadS3({id: row.id}, () => {}, () => {}, "Synced to S3", true, true))
								}}
							/>
						</Tooltip>
						<Tooltip title={"Delete"} placement="top">
							<TrashIcon
								style={iconStyle}
								onClick={() => {
									setSelectedDocumentData(row)
									setConfirmDeleteModalOpen(true)
								}}
							/>
						</Tooltip>
						{/* {row.active ? <Tooltip title={"Deactivate"} placement="top">
							<ToggleOn
								style={iconStyle}
								onClick={() => {
									setSelectedDocumentData({
										...row
									})
									setActivationModalOpen(true);
								}}
							/>
						</Tooltip> :
							<Tooltip title={"Activate"} placement="top">
								<ToggleOff
									style={iconStyle}
									onClick={() => {
										setSelectedDocumentData({
											...row
										})
										setActivationModalOpen(true);
									}}
								/>
							</Tooltip>
						}
						{row.roles?.includes('Admin') ? <Tooltip title={"Demote From Admin"} placement="top">
							<IsAdminUser
								style={iconStyle}
								onClick={() => {
									setSelectedDocumentData({
										...row
									})
									setUserAdminStatusModalOpen(true);
								}}
							/>
						</Tooltip> :
							<Tooltip title={"Promote to Admin"} placement="top">
								<NotAdminUser
									style={iconStyle}
									onClick={() => {
										setSelectedDocumentData({
											...row
										})
										setUserAdminStatusModalOpen(true);
									}}
								/>
							</Tooltip>
						} */}
					</div>
				);
			},
		},
		{
			title: 'File Size', field: 'file_size', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				return <span>{(row.file_size / 1000 / 1000).toFixed(2)}</span>
			}
		},
		{
			title: 'Char Count', field: 'char_count', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Milvus Vectors', field: 'milvus_vectors', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Milvus Error', field: 'error_string', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Source Url',
			field: 'source_url',
			sorting: true,
			cellStyle: {
				width: 300,
				maxWidth: 300,
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				cursor: 'pointer', // Indicate clickable action
			},
			render: (rowData: any) => renderTextEllipsisClipboard(rowData.source_url, 50, dispatch, showInfoMessage),
		},
		{
			title: 'S3 Url',
			field: 's3_path',
			sorting: true,
			cellStyle: {
				width: 300,
				maxWidth: 300,
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				cursor: 'pointer', // Indicate clickable action
			},
			render: (rowData: any) => renderTextEllipsisClipboard(rowData.s3_path, 50, dispatch, showInfoMessage),
		},
		
	]);

	const downloadDocFileUrl = () => {
		let context = "documents"
		if (props.doc_type.toUpperCase() === DOCUMENT_TYPE.CASE_LAW) {
			context = "caselaw"
		}
		return REACT_APP_SERVER_DOMAIN + '/' + context + '/' + selectedDocumentData?.original_file_name?.replace(/%25/g, "%2525")
	}

	const getUpsertDocumentInitialValues = () => {
		if (upsertDocumentReferenceMode === 'create') {
			return { id: null, friendlyTitle: "", meta_data: {} }
		}
		else {
			return { id: selectedDocumentData?.id, friendlyTitle: selectedDocumentData?.friendly_title, 
				meta_data: selectedDocumentData?.meta_data }
		}
	}
	

	return (
		<div>
			<Loading loading={processing} />

			<StandardModal
				title={`Query ${props.doc_type.toUpperCase()} Documents`}
				maxWidth={'xl'}
				open={aiSearchModalOpen}
				onClose={() => setAiSearchModalOpen(false)}
				paragraphs={[]}
				actions={[{
					title: 'Close',
					callback: () => {
						setAiSearchModalOpen(false)
					}
				}, {
					title: 'Query',
					callback: () => {
						return new Promise((resolve, reject) => {
							setProcessing(true);
							const uuid = uuidv4()
							dispatch(searchAIAction({
								query: searchTerm,
								document_type: props.doc_type.toUpperCase(),
								socketId,
								uuid,
								stream: false
							}, (data: any) => {
								if (data.uuid === uuid) {
									setAiResult(data);
									setProcessing(false);
								}
								resolve({})
							}, (e: any) => {
								setProcessing(false)
								console.log("error", e);
								resolve({})
							}, "AI Query Success", true));
						});
					}
				}]}
				customActions={[]}
			>
				<>
					<Box sx={{ '& > :not(style)': { m: 1 } }}>
						<TextField
							fullWidth={true}
							label="Search term"
							variant="outlined"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}


						/>
						<div>
							Query raw AI results on selected collection.
						</div>
						<div>
							<h2>AI Raw Result:</h2>
							<pre style={{
								whiteSpace: 'pre-wrap', // Allows word wrapping
								wordBreak: 'break-all',  // Breaks long words
								backgroundColor: '#3a3b3c', // Black background
								color: 'white', // Light grey text
								padding: '10px', // Some padding for aesthetics
								borderRadius: '5px', // Rounded corners
								fontFamily: 'monospace' // Monospace font for better readability
							}}>{JSON.stringify(aiResult, null, 2)}</pre>
						</div>
					</Box>
				</>
			</StandardModal>


			<StandardModal
				key={upsertDocumentReferenceModalOpen ? 'open' : 'closed'}
				title={upsertDocumentReferenceMode === 'create' ? 'Create Document' : 'Edit Properties'}
				open={upsertDocumentReferenceModalOpen}
				onClose={() => setUpsertDocumentReferenceModalOpen(false)}
				maxWidth={'md'}
				paragraphs={[``]}
				actions={[]}
				customActions={[]}
			>
				<>
					<Box sx={{ '& > :not(style)': { m: 1 } }}>

						<div>
							<UpsertDocumentForm
								key={selectedDocumentData?.id}
								initialValues={getUpsertDocumentInitialValues()}
								mode={upsertDocumentReferenceMode}
								documentType={props.doc_type}
								onDocumentCreateSuccess={(data: any) => {
									setUpsertDocumentReferenceMode('update')
									setTableKey(tableKey + 1)
								}}
								onDocumentUpdateSuccess={(data: any) => {
									setUpsertDocumentReferenceModalOpen(false)
									setTableKey(tableKey + 1)
								}}
								onDocumentUploadSuccess={(data: any) => {
									setUpsertDocumentReferenceModalOpen(false)
									setTableKey(tableKey + 1)
								}}
							/>
						</div>
					</Box>
				</>
			</StandardModal>

			<StandardModal
				title={'Ingest Document Data'}
				open={importDocumentReferenceModalOpen}
				onClose={() => setImportDocumentReferenceModalOpen(false)}
				maxWidth={'xl'}
				paragraphs={[`Would you like to ingest data for ${selectedDocumentData?.friendly_title}`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setImportDocumentReferenceModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						handleUpdateDocumentReference();
					}
				}]}
				customActions={[]}
			>
				<>
					<Box sx={{ '& > :not(style)': { m: 1 } }}>


						<FormControlLabel
							control={<Switch defaultChecked onChange={(e) => { setIngestSolr(e.target.checked) }} />}
							label="Solr"
						/>
						<FormControlLabel
							control={<Switch defaultChecked onChange={(e) => { setIngestEntityExtraction(e.target.checked) }} />}
							label="Entity Extraction"
						/>
						<FormControlLabel
							control={<Switch defaultChecked onChange={(e) => { setIngestVectorize(e.target.checked) }} />}
							label="Vectorize"
						/>
						<div>

							<h2>Ingest Log:</h2>
							<div
								style={{
									maxHeight: '200px', // Limit height
									overflowY: 'auto', // Enable vertical scrolling
									backgroundColor: '#3a3b3c', // Black background
									color: 'white', // Light grey text
									padding: '10px', // Padding for aesthetics
									borderRadius: '5px', // Rounded corners
									fontFamily: 'monospace', // Monospace font
								}}
								ref={(el) => {
									if (el) el.scrollTop = el.scrollHeight; // Scroll to the bottom
								}}
							>
								{indexMessages?.map((x: any, index: number) => {
									console.log(x.date, x.content);
									return (
										<div key={x.date || index}>
											{new Date(x.date).toISOString().slice(0, 19).replace("T", " ")} | {x.content}
										</div>
									);
								})}
							</div>

							<h2>Document Reference:</h2>
							<pre style={{
								whiteSpace: 'pre-wrap', // Allows word wrapping
								wordBreak: 'break-all',  // Breaks long words
								backgroundColor: '#3a3b3c', // Black background
								color: 'white', // Light grey text
								padding: '10px', // Some padding for aesthetics
								borderRadius: '5px', // Rounded corners
								fontFamily: 'monospace' // Monospace font for better readability
							}}>{JSON.stringify(selectedDocumentData, null, 2)}</pre>
						</div>
					</Box>
				</>
			</StandardModal>

			<StandardModal
				title={'Download File'}
				open={downloadDocFileModalOpen}
				onClose={() => setDownloadDocFileModalOpen(false)}
				maxWidth={'xl'}
				paragraphs={[`Would you like to update document reference for ${selectedDocumentData.friendly_title}`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setDownloadDocFileModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						downloadDocFile();
					}
				}]}
				customActions={[]}
			>
				<>
					<Box sx={{ '& > :not(style)': { m: 1 } }}>
						<div>
							Source URL: <a target='_blank' href={selectedDocumentData?.source_url}>{selectedDocumentData?.source_url}</a>
						</div>
						<div>
							MSHA URL: <a target='_blank' href={downloadDocFileUrl()}>{downloadDocFileUrl()}</a>
						</div>
					</Box>
				</>
			</StandardModal>

			<StandardModal
				title={'Vector View'}
				open={vectorizeModalOpen}
				onClose={() => setVectorizeModalOpen(false)}
				maxWidth={'xl'}
				paragraphs={[]}
				actions={[{
					title: 'Okay',
					callback: () => {
						setVectorizeModalOpen(false)
					}
				}]}
				customActions={[]}
			>
				<>
					<Box sx={{ '& > :not(style)': { m: 1 } }}>

						<div>
							<pre style={{
								whiteSpace: 'pre-wrap', // Allows word wrapping
								wordBreak: 'break-all',  // Breaks long words
								backgroundColor: '#3a3b3c', // Black background
								color: 'white', // Light grey text
								padding: '10px', // Some padding for aesthetics
								borderRadius: '5px', // Rounded corners
								fontFamily: 'monospace' // Monospace font for better readability
							}}>{JSON.stringify(vectorizeDocumentResult, null, 2)}</pre>
						</div>
					</Box>
				</>
			</StandardModal>

			<StandardModal
				title={'Solr Document'}
				open={indexDocumentModalOpen}
				maxWidth={'xl'}
				onClose={() => setIndexDocumentModalOpen(false)}
				paragraphs={[`Would you like to index this document in Solr: ${selectedDocumentData.friendly_title}`]}
				actions={[{
					title: 'Okay',
					callback: () => {
						setIndexDocumentModalOpen(false)
					}
				}]}
				customActions={[]}
			>
				<>
					<Box sx={{ '& > :not(style)': { m: 1 } }}>

						<div>
							<h2>Solr get document result:</h2>
							<pre style={{
								whiteSpace: 'pre-wrap', // Allows word wrapping
								wordBreak: 'break-all',  // Breaks long words
								backgroundColor: '#3a3b3c', // Black background
								color: 'white', // Light grey text
								padding: '10px', // Some padding for aesthetics
								borderRadius: '5px', // Rounded corners
								fontFamily: 'monospace' // Monospace font for better readability
							}}>{JSON.stringify(indexDocumentResult, null, 2)}</pre>
						</div>
					</Box>

				</>
			</StandardModal>






			<StandardModal
				title={'Manage Entity Extraction'}
				open={entityExtractionModalOpen}
				maxWidth={'xl'}
				onClose={() => setEntityExtractionModalOpen(false)}
				paragraphs={[`Would you like to re-extract entities for this document: ${selectedDocumentData.friendly_title}`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setEntityExtractionModalOpen(false)
					}
				}, {
					title: 'Re-Extract Entities',
					callback: () => {
						extractEntitiesFromDocument();
					}
				}]}
				customActions={[]}
			>
				<>
					<Box sx={{ '& > :not(style)': { m: 1 } }}>

						<div>
							<h2>Entity Extraction:</h2>
							<pre style={{
								whiteSpace: 'pre-wrap', // Allows word wrapping
								wordBreak: 'break-all',  // Breaks long words
								backgroundColor: '#3a3b3c', // Black background
								color: 'white', // Light grey text
								padding: '10px', // Some padding for aesthetics
								borderRadius: '5px', // Rounded corners
								fontFamily: 'monospace' // Monospace font for better readability
							}}>{JSON.stringify(selectedDocumentData, null, 2)}</pre>
						</div>
					</Box>

				</>
			</StandardModal>




			<StandardModal
				title={'Activation Modal'}
				open={activationModalOpen}
				onClose={() => setActivationModalOpen(false)}
				paragraphs={[`Would you like to ${selectedDocumentData.active ? 'deactivate' : 'activate'} ${selectedDocumentData.email}?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setActivationModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						setProcessing(true)
					}
				}]}
				customActions={[]}
			/>
			<StandardModal
				title={'Confirm Delete Modal'}
				open={confirmDeleteModalOpen}
				onClose={() => setConfirmDeleteModalOpen(false)}
				paragraphs={[`Would you like to permanently delete ${selectedDocumentData.friendly_title}?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setConfirmDeleteModalOpen(false)
					}
				}, {
					title: 'Delete',
					callback: () => {
						dispatch(deleteDocumentReference({
							id: selectedDocumentData.id
						}, () => {
							refreshData();
						}, () => {}, "Document Deleted", true, true))
						setConfirmDeleteModalOpen(false)
					}
				}]}
				customActions={[]}
			/>

			<StandardModal
				title={'Alter User Admin Status'}
				open={userAdminStatusModalOpen}
				onClose={() => setUserAdminStatusModalOpen(false)}
				paragraphs={[`Would you like to ${selectedDocumentData.roles?.includes('Admin') ? 'demote' : 'promote'} ${selectedDocumentData.email} ${selectedDocumentData.roles?.includes('Admin') ? 'from' : 'to'} Admin Status?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setUserAdminStatusModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						setProcessing(true);

					}
				}]}
				customActions={[]}
			/>
			<div key={tableKey + '-' + width} style={{ display: 'block', maxWidth: window.innerWidth - SIDEBAR_WIDTH - MARGIN_LARGE }}>
				<MaterialTable
					icons={tableIcons}
					title={<>{title}&nbsp;&nbsp;<ChatIcon onClick={() => setAiSearchModalOpen(true)} style={iconStyle} />
						<Button
							variant="contained"
							style={{ marginLeft: MARGIN_MEDIUM, backgroundColor: COLORS.BRAND_PRIMARY }}
							startIcon={<AddIcon />}
							onClick={() => {
								setUpsertDocumentReferenceModalOpen(true)
								setUpsertDocumentReferenceMode('create')
							}}
						>
							Add
						</Button></>}
					columns={columns}
					page={page} // Bind current page state
					onPageChange={(newPage) => {
						setTableKey(tableKey + 1)
						setPage(newPage)
					}} // Update state on page change
					// style={{width: '100%'}}
					// style={mainStyle}
					data={(query) => handleTableDataQuery(query)}
					options={{
						sorting: true,
						search: true,
						pageSize: 10,
						pageSizeOptions: [
							10, 25, 50, 100, 200
						],
						paginationAlignment: 'left'

					}}
				/>
			</div>

		</div>

	);
};

export default DocumentTable;
