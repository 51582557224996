

import { push } from "connected-react-router";
import axios from 'axios';
import { call, put, take } from "redux-saga/effects";
import { actionIds } from "../../actions/actionIds";
import { REACT_APP_SERVER_DOMAIN } from "../../constants";
import HttpClient from "../../api/HttpClient";
import { getFileExtension } from "../../utils/general";
import { getS3UploadURL, s3PresignedUrlUploadAction } from "../../actions/documentActions";
import { HttpResponse } from "../../interfaces/HttpResponse";
const client = new HttpClient();


async function getS3UploadUrlAPI(data: any): Promise<HttpResponse<{ x: string }>> {
    return client.post('api/document/get-s3-upload-url', data);
  }

  async function uploadSimpleFile(
    file: File,
    url: string,
    setProgress: (percent: number) => void = () => null
  ): Promise<unknown> {
  
    console.log("send it axios", url, file)
    let result = null;
    try {
      result = await axios.put(url, file, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total!);
          if (setProgress) {
            setProgress(percentage);
          }
        }
      });
    }
    catch (e) {
        console.log("in the error catch!!!")
      console.log(e)
    }
  
    console.log("returning response")
    console.log("result", result)
    return result
  
  }
  

const s3Saga = {

    *getS3UploadURL(action: any) {
        let success = false;
        let response;
        try {
            // @ts-ignore
            response = yield call(getS3UploadUrlAPI, action.payload);
            const successData = response.data;
            success = true;
            yield put({
                type: actionIds.GET_S3_UPLOAD_URL_SUCCESS,
                data: response.data
            });
            if (action.onSuccess) {
                action.onSuccess(successData);
            }
        } catch (e) {
            // tslint:disable-next-line
            console.log(e)
        }
        if (!success) {
            if (action.onFail) {
                action.onFail();
            }
        }
    },

    *uploadDocument(action: any): Generator<any, void, any> {
        if (!action.payload) {
            throw new Error('Payload cannot be empty');
        }

        console.log("upload document debug step 1", action.payload)
        const extension = getFileExtension(action.payload.file.name);
        console.log("extension", extension)
        //Creating resource.
        yield put(getS3UploadURL({
            id: action.payload.id,
            filename: action.payload.file.name,
            documentType: action.payload.documentType
        }));
        const responseAction = (yield take(actionIds.GET_S3_UPLOAD_URL_SUCCESS));
        console.log(responseAction)
        console.log("the url", responseAction.data.uploadUrl)
        

        yield put(
            s3PresignedUrlUploadAction(
              { 
                file: action.payload.file, 
                url: responseAction.data.uploadUrl 
                },
              (data) => action.onSuccess(data),
              action.onFail,
              action.onProgress,
            ),
          );

    },

    *s3PresignedUrlUpload(action: any): Generator {
        const payload = action.payload;
    
        console.log("payload initPresignedUrlSimpleUpload", payload);
    
        const response = (yield call(
          uploadSimpleFile,
          payload.file,
          payload.url,
          //   action.onProgress
        )) as HttpResponse<unknown>;
    
        if (response.status === 200 || response.status === 201) {
        //   yield put({ type: ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS, payload: response });
          if (action.onSuccess) {
            action.onSuccess();
          }
        } else {
          if (action.onFail) {
            action.onFail();
          }
        }
      },


};

export default s3Saga;



