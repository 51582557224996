import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, TextField } from '@mui/material';
import { IDocumentReferenceType } from '../../stack-shared/interfaces/model/document-reference-type.interface';
import { useDispatch } from 'react-redux';
import { updateDocumentType } from '../../actions/documentActions';

interface IDocumentTypeEntityExtractionForm {
  documentType: IDocumentReferenceType;
}

const DocumentTypeEntityExtractionForm: React.FC<IDocumentTypeEntityExtractionForm> = ({ documentType }) => {

  const dispatch = useDispatch();
  // Prepare initial values for Formik
  const initialValues: IDocumentReferenceType = documentType;

  // Stub function for form submission
  const handleSubmit = (values: IDocumentReferenceType) => {
    dispatch(updateDocumentType({documentType: values}))
    console.log('Form submitted with values: ', values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange }) => (
        <Form>
          <div style={{ margin: 16 }}>
            <Field
              name="entity_extraction_person_ignore_list"
              as={TextField}
              fullWidth
              multiline
              label={`${documentType.name} Ignore List Person`}
              rows={3}
              variant="outlined"
              onChange={handleChange}
              value={values.entity_extraction_person_ignore_list || ''}
            />
          </div>
          <div style={{ margin: 16 }}>
            <Field
              name="entity_extraction_location_ignore_list"
              as={TextField}
              fullWidth
              multiline
              label={`${documentType.name} Ignore List Location`}
              rows={3}
              variant="outlined"
              onChange={handleChange}
              value={values.entity_extraction_location_ignore_list || ''}
            />
          </div>
          <div style={{ margin: 16 }}>
            <Field
              name="entity_extraction_organization_ignore_list"
              as={TextField}
              fullWidth
              multiline
              label={`${documentType.name} Ignore List Organization`}
              rows={3}
              variant="outlined"
              onChange={handleChange}
              value={values.entity_extraction_organization_ignore_list || ''}
            />
          </div>
          <div style={{ margin: 16 }}>
            <Field
              name="entity_extraction_person_include_list"
              as={TextField}
              fullWidth
              multiline
              label={`${documentType.name} Include List Person`}
              rows={3}
              variant="outlined"
              onChange={handleChange}
              value={values.entity_extraction_person_include_list || ''}
            />
          </div>
          <div style={{ margin: 16 }}>
            <Field
              name="entity_extraction_location_include_list"
              as={TextField}
              fullWidth
              multiline
              label={`${documentType.name} Include List Location`}
              rows={3}
              variant="outlined"
              onChange={handleChange}
              value={values.entity_extraction_location_include_list || ''}
            />
          </div>
          <div style={{ margin: 16 }}>
            <Field
              name="entity_extraction_organization_include_list"
              as={TextField}
              fullWidth
              multiline
              label={`${documentType.name} Include List Organization`}
              rows={3}
              variant="outlined"
              onChange={handleChange}
              value={values.entity_extraction_organization_include_list || ''}
            />
          </div>
          <Button variant="outlined" type="submit">Submit {documentType.name!} Extraction Lists</Button>
        </Form>
      )}
    </Formik>
  );
};

export default DocumentTypeEntityExtractionForm;


