export enum DOCUMENT_INDEX_STATUS {
    NOT_INDEXED = "NOT_INDEXED",
    INDEXED = "INDEXED",
    FAILED = "FAILED"
}

export enum DOCUMENT_TYPE {
    GUIDANCE = "GUIDANCE",
    MSHA = "MSHA",
    CASE_LAW = "CASE_LAW",
    REGULATION = "REGULATION",
    QUICK_FACT = "QUICK_FACT"
}
