import React from "react";
import SearchViewFilters from "./SearchViewFilters";
import SearchViewResultsPanel from "./SearchViewResultsPanel";
import "./SearchView.css";
import RenderQuickFacts from "./RenderQuickFacts";

interface RegulationWithCount {
  reg: string; // Regulation number
  title: string; // Regulation title
  count: number; // Count of occurrences
}

interface FilterItem {
  label: string; // Label for the filter
  count: number; // Count of occurrences
  isSubFilter?: boolean; // Optional: Whether it is a sub-filter
}
interface SearchViewContentProps {
  solrResultsItems: any;
  totalItems: number;
  searchTerm: string;
  renderCoPilot: React.ReactNode;
  guidanceHighlightingData: any[];
  caselawHighlightingData: any[];
  itemsPerPage: number;
  setItemsPerPage: (value: number) => void;
  onApplyFilter: (value: any) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  facetedNavFilters: RegulationWithCount[]; // Updated type to expect an array of RegulationWithCount
}

const SearchViewContent: React.FC<SearchViewContentProps> = ({
  solrResultsItems,
  totalItems,
  searchTerm,
  renderCoPilot,
  itemsPerPage,
  guidanceHighlightingData,
  caselawHighlightingData,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  facetedNavFilters,
  onApplyFilter
}) => {


  return (
    <div className="searchViewSkeleton">
      <SearchViewFilters facetedNavFilters={facetedNavFilters} onApplyFilter={onApplyFilter} />
      <div className="mainContent">
        {renderCoPilot}
        <SearchViewResultsPanel
          searchTerm={searchTerm}
          totalItems={totalItems}
          solrResultsItems={solrResultsItems}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          guidanceHighlightingData={guidanceHighlightingData}
          caselawHighlightingData={caselawHighlightingData}
        />
      </div>
      <RenderQuickFacts searchTerm={searchTerm} />
    </div>
  );
};

export default SearchViewContent;
