export enum APP_BREAKPOINT {
  EXTRA_SMALL,
  SMALL,
  MEDIUM,
  LARGE,
  EXTRA_LARGE,
}

export enum COLORS {
  WHITE_BACKGROUND = "white",
  APP_BACKGROUND = "#F5F5F5",
  RED_BRAND_PRIMARY = "#ed1c24",
  BLUE_BRAND_PRIMARY = "#1c75bc",
  BLUE_BRAND_PRIMARY_OPACITY20 = "#1c75bc33",
  BACKGROUND_GREY = "#F5F5F5",
  BACKGROUND_INFO = "#0288D1",
  WHITE_PAPER = "#EDEDED",
  GREY_DARK = "#555555",
  WHEEL_2 = "#0099D1",
  WHEEL_3 = "#00BAC9",
  WHEEL_4 = "#00D6AA",
  WHEEL_5 = "#93EC85",
  WHEEL_6 = "#F9F871",
}

export enum EXCEPTION_COLORS {
  WARNING = "#F9A825",
  ERROR = "#D32F2F",
  INFO = "#FF9800",
  DEFAULT = "#808080"
}

export enum STATE_COLORS {
  ACTIVE = "#2E7D32",
  INACTIVE = "#FF9800",
  OBSOLETE = "#135183",
  VOID = "#D32F2F",
}

export const MARGIN_SMALL = 8;
export const MARGIN_MEDIUM = 16;
export const MARGIN_LARGE = 32;

export const errorStyle = {
  color: "red",
};

export const TABLE_DEFAULT_PAGE_SIZE = 15;
export const TABLE_DEFAULT_PAGE_SIZES = [5, 15, 30, 50, 100];

export const APP_BAR_HEIGHT = 64;
export const FOOTER_HEIGHT = 64;
export const SIDEBAR_WIDTH = 190;