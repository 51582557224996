import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { IRootState } from "../../interfaces/rootState";
// import { Router, Route } from "react-router-dom";
import Footer from "../Footer/Footer";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import PrimarySearchAppBar from "../Header/Header";
import PasswordSet from "../PasswordSet/PasswordSet";
import SearchView from "../SearchView/SearchView";
import SignIn from "../Signin/Signin";
import Signup from "../Signup/Signup";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import ThankYou from "../ThankYou/ThankYou";
import UserEdit from "../UserEdit/UserEdit";
import "./App.css";
import UserTable from "../Admin/UserTable";
import AdminContainer from "../Admin/AdminContainer";
import DocumentTable from "../Admin/DocumentTable";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import {
  getPublicData,
  mergeAIResultAction,
  mergeAIResultCaseLawAction,
  pushAdminMessage,
} from "../../actions/genericAction";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import ResponsiveAppBar from "../Header/ReponsiveHeader";
import { Container, Typography, Box } from "@mui/material";
import AiResultTable from "../Admin/AIResultTable";
import DocumentManagement from "../Admin/DocumentManagement";
import Loading from "../General/Loading";
import SearchBaseView from "../SearchView/SearchBaseView/SearchBaseView";
import { refreshTokenAction } from "../../actions/userActions";
import ComplianceInsightsTable from "../Admin/ComplianceInsightsTable";
// import {history} from '../../config/store';

const App = () => {
  const dispatch = useDispatch();
  const error = useSelector((state: IRootState) => state.mainState.error);
  const success = useSelector((state: IRootState) => state.mainState.success);
  const info = useSelector((state: IRootState) => state.mainState.info);
  const loading = useSelector((state: IRootState) => state.mainState.loading);
  const socketId = useSelector((state: IRootState) => state.mainState.socketId);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getPublicData({}, () => {}));
  }, []);

  useEffect(() => {
    if (error && error.error) {
      // console.log("CHANGE IN ERROR", error);
      enqueueSnackbar(error.error, { variant: "error" });
    }
  }, [error]);

  useEffect(() => {
    if (info && info.info) {
      enqueueSnackbar(info.info, { variant: "info" });
    }
  }, [info]);

  useEffect(() => {
    if (success && success.success) {
      enqueueSnackbar(success.success, { variant: "success" });
    }
  }, [success]);

  const loggedUser = useSelector(
    (state: IRootState) => state.mainState.loggedUser
  );
  // console.log("loggedUser", loggedUser);

  useEffect(() => {
    // console.log("useEffect socketId", socketId);

    if (!socketId) {
      console.warn("socketId is undefined, skipping WebSocket connection.");
      return;
    }

    // console.log("process.env.REACT_APP_SERVER_DOMAIN", process.env.REACT_APP_SERVER_DOMAIN);
    // console.log("ESTABLISH SOCKET")
    // Establish WebSocket connection
    const socket = io(
      process.env.REACT_APP_SERVER_DOMAIN ||
        "https://msha-server-dev.303-dev.com",
      {
        withCredentials: true, // Ensure cookies are sent
        query: {
          socketId, // Use the actual data you want to send
        },
      }
    );

    // Handle WebSocket events
    socket.on("connect", () => {
      console.log("WebSocket Connected");
      const jwt = localStorage.getItem("jwt");
      // You can also send authentication data through WebSocket if needed
      socket.emit("authenticate", { socketId, jwt });
    });

    socket.on("userMessage", (message) => {
      console.log("userMessage", message);
      const context = message?.context?.toLowerCase();
      switch(context){
        case "guidance":
          dispatch(
            mergeAIResultAction({ text: message.content, uuid: message.uuid })
          );
          break;
        case "case_law":
          dispatch(
            mergeAIResultCaseLawAction({
              text: message.content,
              uuid: message.uuid,
            })
          );
          break;
        case "ingest":
          console.log("Ingest message", message);
          dispatch(
            pushAdminMessage({
              date: message.date,
              context: message.context,
              content: message.content,
            })
          )
          break;
      }
    });

    socket.on("disconnect", () => {
      // console.log("WebSocket Disconnected");
    });

    // Cleanup on unmount
    return () => {
      socket.disconnect();
    };
  }, [socketId, dispatch]);

  const Maintenance = () => {
    return (
      <div
        style={{
          margin: "0",
          height: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f4f4f9",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            width: "300px",
            height: "100px",
          }}
        >
          <h1 style={{ color: "#333", margin: "0", fontSize: "24px" }}>
            Under Maintenance
          </h1>
          <p style={{ color: "#666", marginTop: "10px", fontSize: "16px" }}>
            We'll be back shortly.
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Loading loading={loading} />
      <Router>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            margin: 0,
            padding: 0,
            backgroundColor: !loggedUser ? "#efeee8" : "#fff", 
          }}
        >
          <ResponsiveAppBar loggedUser={loggedUser} />
          <Container
            component="main"
            sx={{
              mt: 3,
              mb: 2,
              flexGrow: 1,
              padding: "0px !important",
              margin: "0px !important",
              textAlign: "center",
            }}
            maxWidth={false}
          >
            <Routes>
              <Route
                path="/termsAndConditions"
                element={<TermsAndConditions />}
              />
              <Route path="/search" element={<SearchBaseView />} />
              <Route path="/search/regulations" element={<SearchView />} />
              <Route path="/search/guidance" element={<SearchView />} />
              <Route path="/search/case-law" element={<SearchView />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/thankyou" element={<ThankYou />} />
              <Route path="/profile" element={<UserEdit />} />
              <Route path="/admin" element={<AdminContainer />}>
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route
                  path="/admin/users"
                  element={
                    <UserTable
                      title={""}
                      fixed_query_params={{}}
                      onEditUser={function (user: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  }
                />
                <Route
                  path="/admin/ai-results"
                  element={<AiResultTable title={""} fixed_query_params={{}} />}
                />
                <Route
                  path="/admin/guidance-documents"
                  element={
                    <DocumentTable
                      key={"guidance"}
                      doc_type={"guidance"}
                      title={"Guidance Documents"}
                      extra_columns={[
                        {
                          title: "Authority Level",
                          field: "guidance_type",
                          sorting: false,
                          render: (rowData: any) => {
                            return rowData.meta_data?.guidance_type?.join(", ");
                          },
                        },
                        {
                        title: "Industries",
                        field: "industries",
                        sorting: false,
                        render: (rowData: any) => {
                          return rowData.meta_data?.industries?.join(", ");
                        },
                      }]}
                    />
                  }
                />
                <Route
                  path="/admin/case-law"
                  element={
                    <DocumentTable
                      key={"case_law"}
                      doc_type={"case_law"}
                      title={"Case Law Documents"}
                    />
                  }
                />
                <Route
                  path="/admin/compliance-insights"
                  element={
                    <ComplianceInsightsTable title={"Compliance Insights"}/>
                  }
                />
                <Route
                  path="/admin/document-management"
                  element={<DocumentManagement key={"document_management"} />}
                />
              </Route>
              <Route path="/passwordset" element={<PasswordSet />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/" element={<SignIn />} />
            </Routes>
          </Container>
          <Box
            component="footer"
            sx={{
              py: 2,
              px: { xs: 1, sm: 2 }, // Less padding on extra-small devices
              bgcolor: "grey.200",
              width: "100%", // Ensure full width
              boxSizing: "border-box", // Include padding in the width calculation
            }}
          >
            <Footer />
          </Box>
        </Box>
      </Router>
    </>
  );
};

export default App;
