import { Button, TextField } from "@mui/material/";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import zxcvbn from "zxcvbn";
import { passwordSetAction } from "../../actions/userActions";
import { IpasswordSetPost } from "../../interfaces/passwordSetPost";
import "./PasswordSet.css";
import { PasswordSetField } from "./PasswordSetField";
import { useSearchParams } from "react-router-dom";

// Define interface for form values
export interface Values {
  password: string;
}

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Please Enter a Valid Password")
    .test("password-validation", "Password is too common.", (value) => {
      if (!value) return false;
      const result = zxcvbn(value);
      return result.score >= 3;
    })
    .required("Required"),
});

const PasswordSet: React.FC = () => {
  const [changed, setChanged] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // Get query parameters in a more React-like way

  // Handle new password submission
  const handlePasswordSubmit = (values: Values) => {
    const token = searchParams.get("token");
    const email = searchParams.get("email");

    const frmValues = { token, email, password: values.password };

    dispatch(
      passwordSetAction(
        frmValues,
        () => setChanged(true), // Success callback
        () => setChanged(true) // Failure callback (if needed)
      )
    );
  };

  // Redirect after successful password change
  if (changed) {
    return <Navigate replace to="/?passwordChanged=true" />;
  }

  return (
    <div className="LoginContainer">
      <div className="spacing" />
      <Formik
        initialValues={{ password: "" }}
        validationSchema={passwordSchema}
        onSubmit={handlePasswordSubmit}
      >
        {({ errors, touched }) => (
          <Form className="fixedWidthContainer">
            <div className="formHead">
              <h2>Set Password</h2>
            </div>
            <div className="FormContainer">
              <div className="fields">
                <label htmlFor="password">Password</label>
                <Field
                  name="password"
                  placeholder="Password"
                  component={PasswordSetField}
                />
              </div>
              {errors.password && touched.password && (
                <div className="passwordError">{errors.password}</div>
              )}
              <div style={{ marginTop: "16px" }}>
                <Button
                  className="signinButton"
                  type="submit"
                  sx={{
                    borderRadius: "5px",
                    display: "flex",
                    width: "100%",
                    padding: "10px 16px",
                    backgroundColor: " #F66E2F",
                    color: "#ffffff",
                    textTransform: "none",
                    boxShadow: "0px 1px 5px 0px #0000001F",
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "#c0c0c0",
                    },
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordSet;
