import React from "react";
import { MenuItem, FormControl, InputLabel, Select, Checkbox, ListItemText } from "@mui/material/";

interface Option {
  key: string;
  value: any;
}

interface MultiSelectProps {
  options: Option[];
  selectedValues: any[];
  onChange: (event: any) => void;
  label?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, selectedValues, onChange, label = "Select" }) => {
  // Sanitize selectedValues to ensure consistency with options
  const sanitizedSelectedValues = selectedValues.filter((value) =>
    options.some((option) => option.value === value)
  );

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel
        id="multi-select-label"
        style={{
          backgroundColor: "white",
          paddingLeft: 10,
          marginLeft: -4,
          paddingRight: 8,
        }}
        shrink
      >
        {label}
      </InputLabel>
      <Select
        labelId="multi-select-label"
        multiple
        value={sanitizedSelectedValues} // Use sanitized values
        onChange={(event) => {
          // Ensure the change event contains a valid array
          const value = Array.isArray(event.target.value) ? event.target.value : [];
          onChange({ ...event, target: { ...event.target, value } });
        }}
        renderValue={(selected) =>
          // Render selected keys
          options
            .filter((option) => selected.includes(option.value))
            .map((option) => option.key)
            .join(", ")
        }
        label={label}
        style={{ minHeight: 60 }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={sanitizedSelectedValues.includes(option.value)} />
            <ListItemText primary={option.key} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
