import React from "react";
import { MenuItem, FormControl, InputLabel, Select } from "@mui/material/";

interface Option {
  key: string;
  value: any;
}

interface SingleSelectProps {
  options: Option[];
  selectedValue: any; // Single selected value instead of an array
  onChange: (event: any) => void;
  label?: string;
}

const SingleSelect: React.FC<SingleSelectProps> = ({ options, selectedValue, onChange, label = "Select" }) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel
        id="single-select-label"
        style={{
          backgroundColor: "white",
          paddingLeft: 10,
          marginLeft: -4,
          paddingRight: 8,
        }}
        shrink
      >
        {label}
      </InputLabel>
      <Select
        labelId="single-select-label"
        value={selectedValue} // Single value instead of array
        onChange={onChange}
        label={label} // Ensures the label stays properly formatted
        style={{ minHeight: 60 }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.key}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SingleSelect;