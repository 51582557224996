import { Button } from "@mui/material/";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useDispatch } from "react-redux";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material"; // Import MUI icons
import * as Yup from "yup";
import { passwordResetAction } from "../../actions/userActions";
import { SigninField } from "../Signin/SigninField";
import "./ForgotPassword.css"; // Import CSS

// Define interface for form values
export interface Values {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const [successView, setSuccessView] = React.useState<boolean>(false);
  const [resetEmail, setResetEmail] = React.useState<boolean | string | null>(
    false
  );
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null); // To handle error messages
  const dispatch = useDispatch();

  const reset = (values: Values) => {
    // Dispatch the password reset action with success and error callbacks
    dispatch(
      passwordResetAction(
        { email: values.email },
        () => {
          setResetEmail(values.email); // Show success view on success
          setSuccessView(true); // Show success view on success
          setErrorMessage(null); // Clear any existing errors
        },
        (error) => {
          setErrorMessage(
            error.message || "An unexpected error occurred. Please try again."
          );
          setSuccessView(false); // Make sure success view is not shown on error
        },
        "Password reset email sent",
        true
      )
    );
  };

  // Render success view w
  if (successView) {
    return (
      <div className="SuccessContainer">
        <CheckCircleOutline className="icon successIcon" />
        <p>
          Success! If an account with that email {resetEmail} exists, you will
          receive a link to reset your password.
        </p>
      </div>
    );
  }

  // Render error view
  if (errorMessage) {
    return (
      <div className="ErrorContainer">
        <CheckCircleOutline className="icon successIcon" />
        <p>{errorMessage}</p>
      </div>
    );
  }

  return (
    <div className="LoginContainer">
      <Formik
        initialValues={{ email: "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Email is invalid")
            .required("Email is required"),
        })}
        onSubmit={(values) => {
          reset(values);
        }}
      >
        {({ touched, errors }) => (
          <Form className="FormContainer">
            <div className="formHead">
              <h2>Password Reset</h2>
              <p>Please enter your email address.</p>
            </div>
            <div className="FormContainer">
              <div className="fields">
                <label htmlFor="email">Email</label>
                <Field
                  name="email"
                  placeholder="Email"
                  component={SigninField}
                />
              </div>
              <Button
                className="signinButton"
                type="submit"
                sx={{
                  borderRadius: "5px",
                  display: "flex",
                  width: "100%",
                  padding: "10px 16px",
                  backgroundColor: " #F66E2F",
                  color: "#ffffff",
                  textTransform: "none",
                  boxShadow: "0px 1px 5px 0px #0000001F",
                  "&.Mui-disabled": {
                    background: "#eaeaea",
                    color: "#c0c0c0",
                  },
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
