
import { actionIds } from "./actionIds";
import { genericAction } from "./genericAction";

export const getDocumentReferences = genericAction(actionIds.GET_DOCUMENT_REFERENCES);
export const vectorizeDocument = genericAction(actionIds.VECTORIZE_DOCUMENT_REFERENCE);
export const uploadDocument = genericAction(actionIds.UPLOAD_DOCUMENT);
export const getS3UploadURL = genericAction(actionIds.GET_S3_UPLOAD_URL);
export const createDocumentReference = genericAction(actionIds.CREATE_DOCUMENT_REFERENCE);
export const updateDocumentReference = genericAction(actionIds.UPDATE_DOCUMENT_REFERENCE);
export const updateDocumentType = genericAction(actionIds.UPDATE_DOCUMENT_REFERENCE_TYPE);
export const deleteDocumentReference = genericAction(actionIds.DELETE_DOCUMENT_REFERENCE);
export const s3PresignedUrlUploadAction = genericAction(actionIds.S3_PRESIGNED_URL_UPLOAD);
export const ingestDocument = genericAction(actionIds.INGEST_DOCUMENT);
export const milvusGetById = genericAction (actionIds.MILVUS_GET_BY_ID);
export const downloadUploadS3 = genericAction(actionIds.DOWNLOAD_UPLOAD_S3);