import React from "react";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

interface RenderQuickFactsProps {
  searchTerm: string;
}

const RenderQuickFacts: React.FC<RenderQuickFactsProps> = ({ searchTerm }) => {
  return (
    <div className="rightSide rightSideContent">
      <div className="quickFactsContentBlock">
        <div className="blockHeader">
          <h2>
            <LightbulbOutlinedIcon /> Quick Facts
          </h2>
        </div>
        <div className="blockBody">
          <div className="firehose">
            <button className="firehoseBtn">{searchTerm}</button>
          </div>
          <p>
            <strong>Sample Content:</strong> This is placeholder text to
            illustrate how Quick Facts will appear. In actual use, relevant
            information regarding regulations or guidelines will be shown here.
            For example, "PPL P14-IV-02 states that, in many cases, compliance
            with the OSHA fall protection rule, which requires protection only
            at heights about 6 feet, will satisfy this regulation." Note: This
            is dummy content and may not reflect the final text.
          </p>
          <div className="pdfFilesList">
            <a href="/">
              <strong>Sample Link:</strong> 30 CFR 56/57.15005{" "}
              <ArrowForwardOutlinedIcon />
            </a>
          </div>
          <div className="sourceSec">
            <p>Sources: <em>Placeholder Source - PPL P14-IV-02</em></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderQuickFacts;
