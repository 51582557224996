import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  overrides: {
    MuiCheckbox: {
      colorPrimary: "black",
      colorSecondary: "white",
      root: {
        colorPrimary: "black",
        colorSecondary: "white",
      },
    },
  },
  typography: {
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  components: {
    MuiButton: {
      variants: [
        {
          style: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
          },
        },
      ],
    },
  },
});

export default theme;
