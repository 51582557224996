import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import classNames from "classnames";
import "./SearchView.css";
import { useMediaQuery } from "@mui/material";

// Define types for tab information
interface Tab {
  type: string;
  label: string;
  path: string;
  disabled?: boolean;
}

// Define the props type for SearchTabs component
interface SearchTabsProps {
  searchTerm: string;
}

// Helper function to return an icon based on regulation type
export const getIconByRegulationType = (type: string): React.ReactNode => {
  switch (type.toLowerCase()) {
    case "regulations":
      return <AccountBalanceOutlinedIcon />;
    case "guidance":
      return <MenuBookOutlinedIcon />;
    case "case law":
      return <GavelOutlinedIcon />;
    default:
      return null; // Return null if no matching icon
  }
};

const SearchTabs: React.FC<SearchTabsProps> = ({ searchTerm }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:1024px)");

  const tabs: Tab[] = [
    { type: "regulations", label: "REGULATIONS", path: "/search/regulations" },
    { type: "guidance", label: "Guidance", path: "/search/guidance" },
    { type: "case law", label: "Case law", path: "/search/case-law" },
  ];

  // Function to determine the active tab based on the current URL
  const getActiveClass = (path: string): string => {
    return location.pathname.startsWith(path) ? "active" : "";
  };

  // Function to handle tab navigation with search term in URL params
  const handleTabClick = (path: string, isDisabled: boolean) => {
    if (!isDisabled) {
      const urlWithSearchTerm = `${path}?keyword=${encodeURIComponent(searchTerm)}`;
      navigate(urlWithSearchTerm);
    }
  };

  return (
    <div className="searchTabsWrapper">
      <div className="searchTabs">
        {tabs.map((tab) => (
          <span
            key={tab.type}
            className={classNames(getActiveClass(tab.path), { 'disabledTabs': tab.disabled })}
            onClick={() => handleTabClick(tab.path, !!tab.disabled)}
          >
            {getIconByRegulationType(tab.type)} {!isMobile && tab.label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default SearchTabs;
