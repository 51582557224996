import React, { useState } from "react";
import MaterialTable from "@material-table/core";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import HttpClient, { objectToQuery } from "../../api/HttpClient";
import Loading from "../General/Loading";
import StandardModal from "../Shared/StandardModal";
import { dateFormatted } from "../../utils/date-utils";
import { tableIcons } from "../Shared/MaterialTableShared";
import { COLORS } from "../Shared/layout-constants";
import ResultIcon from "@mui/icons-material/PsychologyAlt";
import { Tooltip } from "@mui/material";

const client = new HttpClient();
interface IAiResultTable {
  title: string;
  fixed_query_params: Object;
}

const iconStyle = {
  color: COLORS.BRAND_PRIMARY,
  fontSize: 24,
  cursor: "pointer",
};

const AiResultTable: React.FC<IAiResultTable> = (props) => {
  const [processing, setProcessing] = useState(false);
  const [selectedAiResultData, setSelectedAiResultData] = useState({} as any);
  const [activationModalOpen, setActivationModalOpen] = useState(false);
  const [aiResultAdminStatusModalOpen, setAiResultAdminStatusModalOpen] =
    useState(false);
  const [aiResultTableKey, setAiResultTableKey] = useState(0);
  const { title } = props;

  // const mainStyle = {
  // 	backgroundColor: theme.colors.background,
  // 	color: theme.colors.font,
  // }

  const columns = [
    {
      title: "id",
      field: "id",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
    },
    {
      title: "User ID",
      field: "user_id",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
    },
    {
      title: "User Feedback",
      field: "user_feedback",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
      render: (rowData: any) => {
        switch (rowData.user_feedback) {
          case "THUMBS_UP":
            return "👍 Thumbs Up";
          case "THUMBS_DOWN":
            return "👎 Thumbs Down";
          default:
            return "No Feedback";
        }
      },
    },

    {
      title: "Query Type",
      field: "query_type",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
    },
    {
      title: "Created At",
      field: "created_at",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
      render: (row: any) => {
        return <span>{dateFormatted(row.created_at, "yyyymmdd")}</span>;
      },
    },
    {
      title: "Search Keyword",
      field: "search",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
    },
    {
      title: "Search Result",
      field: "result",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
      render: (row: any) => {
        return (
          <Tooltip title={row.result} placement="top">
            <ResultIcon style={iconStyle} />
          </Tooltip>
        );
      },
    },

    // {
    // 	title: "AiResult Actions",
    // 	render: (row: any) => {
    // 		return (
    // 			<div style={{ cursor: 'pointer' }}>
    // 				<Tooltip title={"View AiResult"} placement="top">
    // 					<Visibility
    // 						style={iconStyle}
    // 						onClick={() => {
    // 							navigate('/AiResults/' + row.id)
    // 						}}
    // 					/>
    // 				</Tooltip>
    // 				<Tooltip title={"Edit AiResult"} placement="top">
    // 					<EditOutlined
    // 						style={iconStyle}
    // 						onClick={() => {
    // 							props.onEditAiResult(row);
    // 						}}
    // 					/>
    // 				</Tooltip>
    // 				{row.active ? <Tooltip title={"Deactivate"} placement="top">
    // 					<ToggleOn
    // 						style={iconStyle}
    // 						onClick={() => {
    // 							setSelectedAiResultData({
    // 								...row
    // 							})
    // 							setActivationModalOpen(true);
    // 						}}
    // 					/>
    // 				</Tooltip> :
    // 					<Tooltip title={"Activate"} placement="top">
    // 						<ToggleOff
    // 							style={iconStyle}
    // 							onClick={() => {
    // 								setSelectedAiResultData({
    // 									...row
    // 								})
    // 								setActivationModalOpen(true);
    // 							}}
    // 						/>
    // 					</Tooltip>
    // 				}
    // 				{row.roles?.includes('Admin') ? <Tooltip title={"Demote From Admin"} placement="top">
    // 					<IsAdminAiResult
    // 						style={iconStyle}
    // 						onClick={() => {
    // 							setSelectedAiResultData({
    // 								...row
    // 							})
    // 							setAiResultAdminStatusModalOpen(true);
    // 						}}
    // 					/>
    // 				</Tooltip> :
    // 					<Tooltip title={"Promote to Admin"} placement="top">
    // 						<NotAdminAiResult
    // 							style={iconStyle}
    // 							onClick={() => {
    // 								setSelectedAiResultData({
    // 									...row
    // 								})
    // 								setAiResultAdminStatusModalOpen(true);
    // 							}}
    // 						/>
    // 					</Tooltip>
    // 				}
    // 			</div>
    // 		);
    // 	},
    // }
  ];

  return (
    <div>
      <Loading loading={processing} />
      <StandardModal
        title={"Activation Modal"}
        open={activationModalOpen}
        onClose={() => setActivationModalOpen(false)}
        paragraphs={[
          `Would you like to ${
            selectedAiResultData.active ? "deactivate" : "activate"
          } ${selectedAiResultData.email}?`,
        ]}
        actions={[
          {
            title: "Cancel",
            callback: () => {
              setActivationModalOpen(false);
            },
          },
          {
            title: "Yes",
            callback: () => {
              setProcessing(true);
            },
          },
        ]}
        customActions={[]}
      />
      <StandardModal
        title={"Alter AiResult Admin Status"}
        open={aiResultAdminStatusModalOpen}
        onClose={() => setAiResultAdminStatusModalOpen(false)}
        paragraphs={[
          `Would you like to ${
            selectedAiResultData.roles?.includes("Admin") ? "demote" : "promote"
          } ${selectedAiResultData.email} ${
            selectedAiResultData.roles?.includes("Admin") ? "from" : "to"
          } Admin Status?`,
        ]}
        actions={[
          {
            title: "Cancel",
            callback: () => {
              setAiResultAdminStatusModalOpen(false);
            },
          },
          {
            title: "Yes",
            callback: () => {
              setProcessing(true);
            },
          },
        ]}
        customActions={[]}
      />
      <div key={aiResultTableKey} style={{ display: "block" }}>
        <MaterialTable
          icons={tableIcons}
          title={title}
          columns={columns}
          // style={{width: '100%'}}
          // style={mainStyle}
          data={(query: any) =>
            new Promise((resolve, reject) => {
              let url = "api/ai/query";

              const params = {
                take: query.pageSize,
                skip: query.page * query.pageSize,
                search: query.search,
                ...props.fixed_query_params,
              } as any;
              if (query.orderBy?.field) {
                params.orderBy = String(query.orderBy.field);
              }
              if (query.orderDirection) {
                params.orderDirection = String(query.orderDirection);
              }
              //url += objectToQuery(params);

              (async () => {
                try {
                  const result = await client.get(url, params);
                  if (result.data) {
                    resolve({
                      data: result.data.data,
                      page: query.page,
                      totalCount: result.data.total,
                    });
                  } else {
                    reject(new Error("Un-Authorized"));
                  }
                } catch (error) {
                  reject(error);
                }
              })();
            }) as any
          }
          options={{
            sorting: true,
            search: false,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100, 200],
            // headerStyle: mainStyle,
            // searchFieldStyle: mainStyle,
            // headerStyle: {
            // 	width: '25%',
            // 	maxWidth: '25%',  // Try adjusting these values
            //   },
            paginationAlignment: "left",
          }}
          // components={{
          // 	Pagination: props => (
          // 		<TablePagination
          // 			{...props}
          // 			style={{ backgroundColor: 'grey'}}
          // 		/>
          // 	),
          // }}
        />
      </div>
    </div>
  );
};

export default AiResultTable;
